import React, { useState } from 'react';
import ThemeToggle from './ThemeToggle';

const UserActions: React.FC = () => {

  return (
    <div className="flex items-center space-x-4 text-white">
      <ThemeToggle />
    </div>
  );
};

export default UserActions;