import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navigation from './Navigation';
import UserActions from './UserActions';
import { Search, X } from 'lucide-react';
import { PCComponent } from '../../types/types';
import { assetLoader } from '../../services/assetLoader';
import { priceService } from '../../services/priceService';
import * as THREE from 'three';

const Header: React.FC = () => {
    const [showSearch, setShowSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<PCComponent[]>([]);
    const [allParts, setAllParts] = useState<PCComponent[]>([]);
    const searchRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const loadAllParts = async () => {
            await assetLoader.loadManifest();
            const categories = assetLoader.getCategories();
            const parts: PCComponent[] = [];
            
            for (const category of categories) {
                const modelNames = await assetLoader.getModels(category);
                parts.push(...modelNames.map(modelName => ({
                    id: `${category}_${modelName}`,
                    modelName,
                    category,
                    present: false,
                    position: new THREE.Vector3(),
                    rotation: new THREE.Euler(),
                    obj: null,
                    dimensions: new THREE.Vector3(),
                    parentId: null
                })));
            }
            
            setAllParts(parts);
        };

        loadAllParts();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
                setShowSearch(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSearch = (value: string) => {
        setSearchTerm(value);
        if (value.trim() === '') {
            setSearchResults([]);
            return;
        }

        const filtered = allParts.filter(part => 
            part.modelName.toLowerCase().includes(value.toLowerCase()) ||
            part.category.toLowerCase().includes(value.toLowerCase())
        );
        setSearchResults(filtered.slice(0, 5)); // Limit to 5 results
    };

    const handleResultClick = (part: PCComponent) => {
        setShowSearch(false);
        setSearchTerm('');
        setSearchResults([]);
        navigate(`/products?category=${encodeURIComponent(part.category)}&model=${encodeURIComponent(part.modelName)}`);
    };

    const getPricing = (part: PCComponent) => {
        return priceService.getPricing(part.id);
    };
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    return (
        <header className="header relative">
            {showSearch && <div className="search-overlay" onClick={() => setShowSearch(false)} />}
            <div className="container mx-auto px-4">
                <div className="flex items-center justify-between py-4 md:py-2">
                    <Link to="/" className="flex items-center space-x-2 text-white shrink-0">
                        <img src="/favicon.ico" alt="3D PC Builder logo" className="w-8 h-8 md:w-10 md:h-10" />
                        <span className="text-lg md:text-xl font-bold">3D PC Builder</span>
                    </Link>
                    
                    <div className="hidden md:flex flex-1 justify-center">
                        <Navigation />
                    </div>

                    <div className="flex items-center space-x-4">
                        <div className="relative" ref={searchRef}>
                            {showSearch && (
                                <div className="search-container">
                                    <div className="search-input relative">
                                        <input
                                            type="text"
                                            placeholder="Search components..."
                                            className="w-full px-4 py-3 border rounded-lg bg-[#252525] text-white shadow-lg pr-10 border-[#2d2d2d] focus:outline-none focus:border-blue-500"
                                            autoFocus
                                            value={searchTerm}
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />
                                        {searchTerm && (
                                            <X 
                                                className="absolute right-2 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500 cursor-pointer hover:text-gray-300"
                                                onClick={() => {
                                                    setSearchTerm('');
                                                    setSearchResults([]);
                                                }}
                                            />
                                        )}
                                    </div>
                                    {searchResults.length > 0 && (
                                        <div className="fixed left-0 right-0 mx-auto mt-2 bg-[#252525] rounded-lg shadow-xl max-h-[70vh] overflow-y-auto border border-[#2d2d2d] w-full max-w-[600px]">
                                            {searchResults.map((result) => (
                                                <div
                                                    key={result.id}
                                                    className="p-4 hover:bg-[#2d2d2d] cursor-pointer border-b border-[#2d2d2d] last:border-b-0 transition-colors duration-200"
                                                    onClick={() => handleResultClick(result)}
                                                >
                                                    <div className="flex items-center gap-4">
                                                        <div className="w-16 h-16 bg-[#1e1e1e] rounded-lg overflow-hidden flex-shrink-0">
                                                            <img
                                                                src={`/images/${result.category}/${result.modelName}.png`}
                                                                onError={(e) => {
                                                                    const img = e.currentTarget;
                                                                    if (!img.src.endsWith('.jpg')) {
                                                                        img.src = `/images/${result.category}/${result.modelName}.jpg`;
                                                                    } else {
                                                                        img.style.display = 'none';
                                                                    }
                                                                }}
                                                                alt={result.modelName}
                                                                className="w-full h-full object-contain"
                                                            />
                                                        </div>
                                                        <div className="flex-grow min-w-0">
                                                            <div className="font-medium text-sm text-white truncate">{result.modelName}</div>
                                                            <div className="text-xs text-gray-400 mb-1">{result.category}</div>
                                                            <div className="flex items-center justify-between">
                                                                <div className="text-sm font-medium text-green-500">${getPricing(result).price}</div>
                                                                <div className="text-xs text-yellow-500">★ {getPricing(result).rating}/5</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                            <Search 
                                className="cursor-pointer text-white w-6 h-6" 
                                onClick={() => setShowSearch(true)} 
                            />
                        </div>
                        <UserActions />
                    </div>

                    <div className="md:hidden">
                        <button 
                            className="text-white p-2" 
                            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                            aria-label="Toggle menu"
                        >
                            {isMobileMenuOpen ? (
                                <X className="w-6 h-6" />
                            ) : (
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            )}
                        </button>
                    </div>

                    <div className={`mobile-nav md:hidden ${isMobileMenuOpen ? 'open' : ''}`}>
                        <div className="p-4">
                            <Navigation onClick={() => setIsMobileMenuOpen(false)} />
                        </div>
                    </div>
                </div>
            </div>
        </header>
  );
};

export default Header;
