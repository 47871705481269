import React from 'react';
import styles from '../PCBuilderControls.module.css';
import { LightingOptions } from '../../../types/types';

interface LightPresetsProps {
  saveLightPreset: () => void;
  loadLightPreset: (index: number) => void;
  lightPresets: LightingOptions[];
}

const LightPresets: React.FC<LightPresetsProps> = ({ saveLightPreset, loadLightPreset, lightPresets }) => {
  return (
    <div className={styles.section}>
      <h3 className={styles.sectionTitle}>Light Constellations</h3>
      <button onClick={saveLightPreset} className={styles.button}>Save Light Constellation</button>
      {lightPresets.map((_, index) => (
        <button key={index} onClick={() => loadLightPreset(index)} className={styles.button}>
          Load Constellation {index + 1}
        </button>
      ))}
    </div>
  );
};

export default LightPresets;