import React from 'react';
import styles from '../PCBuilderControls.module.css';
import { MaterialType } from '../../../types/types';

interface MaterialSettingsProps {
  materialType: MaterialType;
  setMaterialType: (type: MaterialType) => void;
}

const MaterialSettings: React.FC<MaterialSettingsProps> = ({ materialType, setMaterialType }) => {
  return (
    <div className={styles.section}>
      <h3 className={styles.sectionTitle}>Material Composition</h3>
      <div className={styles.buttonGroup}>
        {['rubber', 'window', 'coat', 'paint'].map((type) => (
          <button 
            key={type} 
            onClick={() => setMaterialType(type as MaterialType)}
            className={`${styles.button} ${type === materialType ? styles.activeButton : ''}`}
          >
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MaterialSettings;