import React from 'react';
import { Link } from 'react-router-dom';
import { NAV_ITEMS } from '../../services/constants';

interface NavigationProps {
  onClick?: () => void;
}

const Navigation: React.FC<NavigationProps> = ({ onClick }) => {
  return (
    <nav className="w-full">
      <ul className="flex md:space-x-8 flex-col md:flex-row space-y-4 md:space-y-0 justify-center items-center">
        {NAV_ITEMS.map((item) => (
          <li key={item.name}>
            <Link 
              to={item.href} 
              className="nav-link flex items-center w-full md:w-auto justify-center" 
              onClick={onClick}
            >
              <item.icon className="w-4 h-4 mr-1" />
              <span>{item.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
