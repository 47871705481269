import React, { useRef, useEffect, useState } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { PointerLockControls } from '@react-three/drei';
import * as THREE from 'three';
import Crosshair from './Crosshair';
import e from 'cors';

interface FirstPersonPlayerProps {
  clampPosition: (position: THREE.Vector3) => THREE.Vector3;
}

const FirstPersonPlayer: React.FC<FirstPersonPlayerProps> = ({ clampPosition }) => {
  const { camera, gl } = useThree();
  const controlsRef = useRef<any>(null);
  const [isActive, setIsActive] = useState(false);
  const playerRef = useRef<THREE.Object3D>(new THREE.Object3D());
  const velocityRef = useRef(new THREE.Vector3());
  const moveForward = useRef(false);
  const moveBackward = useRef(false);
  const moveLeft = useRef(false);
  const moveRight = useRef(false);
  const canJump = useRef(true);
  const isCrouching = useRef(false);

  const standingHeight = 1.8;
  const crouchingHeight = 0.8;
  const jumpVelocity = 5;
  const gravity = -9.8;
  const playerSpeed = 5;
  const crouchingSpeed = 2.5;
  const minPolarAngle = THREE.MathUtils.degToRad(1);
  const maxPolarAngle = THREE.MathUtils.degToRad(179);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.code) {
        case 'KeyW': moveForward.current = true; break;
        case 'KeyS': moveBackward.current = true; break;
        case 'KeyA': moveLeft.current = true; break;
        case 'KeyD': moveRight.current = true; break;
        case 'Space': 
          if (canJump.current) {
            velocityRef.current.y = jumpVelocity;
            canJump.current = false;
          }
          break;
        case 'KeyC':
          isCrouching.current = !isCrouching.current;
          if (isCrouching.current) {
            camera.position.y -= (standingHeight - crouchingHeight) / 2;
          } else {
            camera.position.y += (standingHeight - crouchingHeight) / 2;
          }
          break;
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      switch (event.code) {
        case 'KeyW': moveForward.current = false; break;
        case 'KeyS': moveBackward.current = false; break;
        case 'KeyA': moveLeft.current = false; break;
        case 'KeyD': moveRight.current = false; break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [camera]);

  useFrame((state, delta) => {
    if (controlsRef.current && controlsRef.current.isLocked) {
      const player = playerRef.current;
      const velocity = velocityRef.current;

      // Apply gravity
      velocity.y += gravity * delta;

      // Calculate movement direction relative to camera
      const direction = new THREE.Vector3();
      const cameraDirection = new THREE.Vector3();
      camera.getWorldDirection(cameraDirection);

      direction.z = Number(moveForward.current) - Number(moveBackward.current);
      direction.x = Number(moveLeft.current) - Number(moveRight.current);
      direction.normalize();

      // Rotate the direction based on the camera's horizontal rotation only
      const horizontalRotation = new THREE.Euler(0, Math.atan2(cameraDirection.x, cameraDirection.z), 0);
      direction.applyEuler(horizontalRotation);

      // Apply movement
      const currentSpeed = isCrouching.current ? crouchingSpeed : playerSpeed;
      velocity.x = direction.x * currentSpeed;
      velocity.z = direction.z * currentSpeed;

      player.position.addScaledVector(velocity, delta);

      // Check bounds and adjust position
      player.position.copy(clampPosition(player.position));

      // Check if player is on the ground
      const currentHeight = isCrouching.current ? crouchingHeight : standingHeight;
      if (player.position.y <= currentHeight / 2) {
        velocity.y = Math.max(0, velocity.y);
        player.position.y = currentHeight / 2;
        canJump.current = true;
      }

      // Update camera position
      camera.position.copy(player.position);
      camera.position.y += currentHeight / 2;
    }
  });

  useEffect(() => {
    const canvas = gl.domElement;
    
    const handleCanvasClick = (event: MouseEvent) => {
      //console.log("enter hit: " + event.target)
      if (!isActive && event.target === canvas) {
        setIsActive(true);
        controlsRef.current?.lock();
      }
    };
    const handleExitPointerLock = () => {
      //console.log("exit hit: " + document.pointerLockElement)
      if (document.pointerLockElement === null) {
        setIsActive(false);
      }
    };

    document.addEventListener('click', handleCanvasClick);
    document.addEventListener('pointerlockchange', handleExitPointerLock);

    return () => {
      document.removeEventListener('click', handleCanvasClick);
      document.removeEventListener('pointerlockchange', handleExitPointerLock);
    };
  }, [gl, isActive]);
  return (
    <>
      {isActive && (
        <>
          <PointerLockControls 
            ref={controlsRef} 
            minPolarAngle={minPolarAngle}
            maxPolarAngle={maxPolarAngle}
          />
          <primitive object={playerRef.current} />
          <Crosshair />
        </>
      )}
    </>
  );
};

export default FirstPersonPlayer;