import {Cpu , PcCase } from 'lucide-react';

export interface NavigationItem {
  name: string;
  href: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

// Theme type for app theme
export type Theme = 'light' | 'dark';

// ViewMode type for part list view
export type ViewMode = 'overview' | 'byMerchant';

// ButtonVariant type for button styles
export type ButtonVariant = 'primary' | 'secondary' | 'ghost';

// ButtonSize type for button sizes
export type ButtonSize = 'sm' | 'md' | 'lg';

export const NAV_ITEMS: NavigationItem[] = [
  { name: 'Products', href: '/products', icon: Cpu },
  { name: 'Completed Builds', href: '/completed-builds', icon: PcCase },
];

export const INITIAL_PARTS = [
  {
    id: '1',
    component: 'CPU',
    selection: 'AMD Ryzen 5 7600X 4.7 GHz 6-Core Processor',
    base: 299.99,
    promo: 0,
    shipping: 0,
    tax: 0,
    price: 299.99,
  },
  {
    id: '2',
    component: 'CPU Cooler',
    selection: 'Cooler Master Hyper 212 EVO 82.9 CFM Sleeve Bearing CPU Cooler',
    base: 34.99,
    promo: 0,
    shipping: 0,
    tax: 0,
    price: 34.99,
  },
  {
    id: '3',
    component: 'Motherboard',
    selection: 'MSI B550M PRO-VDH WIFI Micro ATX AM4 Motherboard',
    base: 109.99,
    promo: 10,
    shipping: 0,
    tax: 0,
    price: 99.99,
  },
  {
    id: '4',
    component: 'Memory',
    selection: 'Corsair Vengeance LPX 16 GB (2 x 8 GB) DDR4-3200 CL16 Memory',
    base: 59.99,
    promo: 0,
    shipping: 0,
    tax: 0,
    price: 59.99,
  },
  {
    id: '5',
    component: 'Storage',
    selection: 'Samsung 970 EVO Plus 1 TB M.2-2280 PCIe 3.0 X4 NVME Solid State Drive',
    base: 89.99,
    promo: 0,
    shipping: 0,
    tax: 0,
    price: 89.99,
  },
  {
    id: '6',
    component: 'Video Card',
    selection: 'NVIDIA GeForce RTX 3060 Ti 8 GB Founders Edition Video Card',
    base: 399.99,
    promo: 0,
    shipping: 0,
    tax: 0,
    price: 399.99,
  },
];