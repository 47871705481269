import React from 'react';
import PCBuilderScene from '../components/Scene/PCBuilderScene';

const Builder: React.FC = () => {
  return (

    <div className="flex-1 w-full">
      <PCBuilderScene />
    </div>
    
  );
};

export default Builder;
