import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PCComponent } from '../types/types';
import { assetLoader } from '../services/assetLoader';
import { priceService } from '../services/priceService';
import * as THREE from 'three';
import { Filter, Grid, List } from 'lucide-react';
import { useProductPreferences } from '../hooks/useProductPreferences';

const Products: React.FC = () => {
  const {
    itemsPerPage,
    setItemsPerPage,
    itemsPerRow,
    setItemsPerRow,
    useInfiniteScroll,
    setUseInfiniteScroll,
    sortBy,
    setSortBy,
    selectedCategory,
    setSelectedCategory
  } = useProductPreferences();

  const [products, setProducts] = useState<PCComponent[]>([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [paginatedProducts, setPaginatedProducts] = useState<PCComponent[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  // Load products
  useEffect(() => {
    const loadProducts = async () => {
      setCurrentPage(1);
      try {
        await assetLoader.loadManifest();
        const cats = assetLoader.getCategories();
        setCategories(cats);
        
        const allProducts: PCComponent[] = [];
        for (const category of cats) {
          const models = await assetLoader.getModels(category);
          allProducts.push(...models.map(modelName => ({
            id: `${category}_${modelName}`,
            modelName,
            category,
            present: false,
            position: new THREE.Vector3(),
            rotation: new THREE.Euler(),
            obj: null,
            dimensions: new THREE.Vector3(),
            parentId: null
          })));
        }
        setProducts(allProducts);
      } catch (error) {
        console.error('Error loading products:', error);
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, []);

  // Cache product pricing
  const productPricing = useMemo(() => {
    return products.reduce((acc, product) => {
      const pricing = priceService.getPricing(product.id);
      acc[product.id] = {
        price: parseFloat(pricing.price),
        rating: parseFloat(pricing.rating)
      };
      return acc;
    }, {} as Record<string, { price: number; rating: number }>);
  }, [products]);

  // Filter and sort products with memoization
  const filteredAndSortedProducts = useMemo(() => products
    .filter(product => selectedCategory === 'all' || product.category === selectedCategory)
    .sort((a, b) => {
      const priceA = productPricing[a.id].price;
      const priceB = productPricing[b.id].price;
      const ratingA = productPricing[a.id].rating;
      const ratingB = productPricing[b.id].rating;

      switch (sortBy) {
        case 'price-asc':
          return priceA - priceB;
        case 'price-desc':
          return priceB - priceA;
        case 'rating':
          return ratingB - ratingA;
        default:
          return 0;
      }
    }), [products, selectedCategory, sortBy, productPricing]);

  // Memoize pagination calculations
  const { startIndex, endIndex } = useMemo(() => {
    const start = useInfiniteScroll ? 0 : (currentPage - 1) * itemsPerPage;
    const end = useInfiniteScroll ? currentPage * itemsPerPage : start + itemsPerPage;
    return { startIndex: start, endIndex: end };
  }, [currentPage, itemsPerPage, useInfiniteScroll]);

  // Update paginated products
  useEffect(() => {
    if (loading) return;
    
    const newPaginatedProducts = filteredAndSortedProducts.slice(0, endIndex);
    setPaginatedProducts(newPaginatedProducts);
    setHasMore(endIndex < filteredAndSortedProducts.length);
  }, [endIndex, filteredAndSortedProducts, loading]);

  // Setup infinite scroll observer
  useEffect(() => {
    if (!useInfiniteScroll || loading) return;

    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setCurrentPage(prev => prev + 1);
      }
    }, options);

    if (lastElementRef.current) {
      observer.current.observe(lastElementRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [hasMore, useInfiniteScroll, loading]);

  const totalPages = useMemo(() => 
    Math.ceil(filteredAndSortedProducts.length / itemsPerPage),
    [filteredAndSortedProducts.length, itemsPerPage]
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="animate-pulse space-y-8">
          <div className="h-8 bg-gray-700 rounded w-1/4"></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {[...Array(8)].map((_, i) => (
              <div key={i} className="bg-[#1e1e1e] rounded-lg p-4 space-y-4">
                <div className="aspect-square bg-gray-700 rounded-lg"></div>
                <div className="h-4 bg-gray-700 rounded w-3/4"></div>
                <div className="h-4 bg-gray-700 rounded w-1/2"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 gap-4">
        <h1 className="text-3xl font-bold">Products</h1>
        <div className="flex flex-wrap gap-2 md:gap-4">
          {/* Display settings */}
          <div className="flex items-center gap-2 bg-[#1e1e1e] rounded-lg p-2">
            <Grid className="w-5 h-5 text-gray-400" />
            <select
              value={itemsPerRow}
              onChange={(e) => setItemsPerRow(Number(e.target.value))}
              className="bg-transparent text-white focus:outline-none [&>option]:bg-[#1e1e1e] [&>option]:text-white"
            >
              <option value={3}>3 per row</option>
              <option value={4}>4 per row</option>
              <option value={6}>6 per row</option>
              </select>
            </div>

          {/* Items per page */}
          <div className="flex items-center gap-2 bg-[#1e1e1e] rounded-lg p-2">
            <List className="w-5 h-5 text-gray-400" />
            <select
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
              className="bg-transparent text-white focus:outline-none [&>option]:bg-[#1e1e1e] [&>option]:text-white"
              >
                <option value={12}>12 per page</option>
                <option value={24}>24 per page</option>
                <option value={48}>48 per page</option>
              </select>
            </div>

          {/* Infinite scroll toggle */}
          <div className="flex items-center gap-2 bg-[#1e1e1e] rounded-lg p-2">
            <label className="text-sm text-gray-400">Infinite scroll</label>
            <input
              type="checkbox"
              checked={useInfiniteScroll}
              onChange={() => {
                setUseInfiniteScroll(!useInfiniteScroll);
                setCurrentPage(1);
              }}
              className="form-checkbox h-4 w-4 text-blue-500"
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-2 md:gap-4 items-center">
          <div className="flex items-center gap-2 bg-[#1e1e1e] rounded-lg p-2">
            <Filter className="w-5 h-5 text-gray-400" />
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="bg-transparent text-white focus:outline-none [&>option]:bg-[#1e1e1e] [&>option]:text-white"
            >
              <option value="all">All Categories</option>
              {categories.map(cat => (
                <option key={cat} value={cat}>{cat.charAt(0).toUpperCase() + cat.slice(1)}</option>
              ))}
            </select>
          </div>

          <div className="bg-[#1e1e1e] rounded-lg p-2">
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value as 'price-asc' | 'price-desc' | 'rating')}
              className="bg-transparent text-white focus:outline-none [&>option]:bg-[#1e1e1e] [&>option]:text-white"
            >
              <option value="rating">Top Rated</option>
              <option value="price-asc">Price: Low to High</option>
              <option value="price-desc">Price: High to Low</option>
            </select>
          </div>
        </div>
      </div>

      <div className={`grid gap-6`} 
        style={{
          gridTemplateColumns: `repeat(auto-fit, minmax(${window.innerWidth < 640 ? '150px' : '250px'}, 1fr))`
        }}>
        {paginatedProducts.map((product) => {
          const pricing = productPricing[product.id];
          return (
            <Link
              key={product.id}
              to={`/product/${product.category}/${product.modelName}`}
              className="bg-[#1e1e1e] rounded-lg overflow-hidden hover:bg-[#252525] transition-colors group"
            >
              <div className="aspect-square p-4 bg-[#181818]">
                <img
                  src={`/images/${product.category}/${product.modelName}.png`}
                  onError={(e) => {
                    const img = e.currentTarget;
                    if (!img.src.endsWith('.jpg')) {
                      img.src = `/images/${product.category}/${product.modelName}.jpg`;
                    } else {
                      img.style.display = 'none';
                    }
                  }}
                  alt={product.modelName}
                  className="w-full h-full object-contain group-hover:scale-105 transition-transform"
                />
              </div>
              <div className="p-4">
                <h3 className="font-medium mb-1 truncate">{product.modelName}</h3>
                <p className="text-sm text-gray-400 mb-2">{product.category}</p>
                <div className="flex items-center justify-between">
                  <span className="text-lg font-semibold text-green-500">${pricing.price.toFixed(2)}</span>
                  <div className="flex items-center text-yellow-500">
                    <span className="text-sm font-medium mr-1">{pricing.rating.toFixed(1)}</span>
                    <span className="text-xs">★</span>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
        
        {/* Infinite scroll observer element */}
        {useInfiniteScroll && hasMore && (
          <div ref={lastElementRef} className="col-span-full h-10 flex items-center justify-center">
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
          </div>
        )}
      </div>

      {/* Pagination controls */}
      {!useInfiniteScroll && filteredAndSortedProducts.length > 0 && (
        <div className="mt-8 flex justify-center gap-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 rounded-lg bg-[#1e1e1e] disabled:opacity-50"
          >
            Previous
          </button>
          
          {Array.from({ length: totalPages }, (_, i) => i + 1)
            .filter(page => {
              const distance = Math.abs(page - currentPage);
              return distance === 0 || distance === 1 || page === 1 || page === totalPages;
            })
            .map((page, index, array) => (
              <React.Fragment key={page}>
                {index > 0 && array[index - 1] !== page - 1 && (
                  <span className="px-4 py-2">...</span>
                )}
                <button
                  onClick={() => handlePageChange(page)}
                  className={`px-4 py-2 rounded-lg ${
                    currentPage === page ? 'bg-blue-500' : 'bg-[#1e1e1e]'
                  }`}
                >
                  {page}
                </button>
              </React.Fragment>
            ))}
          
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 rounded-lg bg-[#1e1e1e] disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}

      {filteredAndSortedProducts.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-400">No products found in this category.</p>
        </div>
      )}
    </div>
  );
};

export default Products;
