import React, { useRef, useEffect, useState, useMemo, Suspense, useCallback } from 'react';
import * as THREE from 'three';
import { Perf } from 'r3f-perf';
import { TransformControls as TransformControlsImpl, OrbitControls as OrbitControlsImpl } from 'three-stdlib'
import { useThree, useFrame, useLoader } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, TransformControls, Environment, FirstPersonControls, Text } from '@react-three/drei';
import { PCComponent, PC, LightingOptions, TableOptions, CaseOptions } from '../../types/types';
import FirstPersonPlayer from './components/FirstPersonPlayer';
import { createPositionClamper } from '../../services/utils'
import EnvironmentLoader from './components/EnvironmentLoader';
import { Tooltip } from './components/Tooltip';
import CustomObject3D from './components/CustomObject3D';

const LazyRoom3D = React.lazy(() => import('./components/Room3D'));

interface RealisticSceneProps {
  components: PCComponent[];
  onUpdateComponent: (id: string, newPosition: THREE.Vector3, newRotation: THREE.Euler) => void;
  lightingOptions: LightingOptions;
  materialType: string;
  caseOptions: CaseOptions;
  pc: PC,
  setPc: React.Dispatch<React.SetStateAction<PC>>
  highResEnvPath: string;
  lowResEnvPath: string;
  rotation: [number, number, number];
  resetCamera: boolean;
  onResetComplete: () => void;
}

const RealisticScene: React.FC<RealisticSceneProps> = ({ 
  components, 
  onUpdateComponent, 
  lightingOptions,
  materialType,
  caseOptions,
  pc,
  setPc,
  highResEnvPath,
  lowResEnvPath,
  rotation,
  resetCamera,
  onResetComplete,
}) => {
  const { scene, camera, gl } = useThree();
  const [selectedComponent, setSelectedComponent] = useState<string | null>(null);
  const [controlsPosition, setControlsPosition] = useState<THREE.Vector3 | null>(null);
  const [transformMode, setTransformMode] = useState<'translate' | 'rotate'>('translate');
  const orbitControlsRef = useRef<OrbitControlsImpl>(null);
  const [controlMode, setControlMode] = useState<'orbit' | 'firstPerson'>('orbit');
  const [showTooltip, setShowTooltip] = useState(false);
  const [showInitialTooltip, setShowInitialTooltip] = useState(true);
  const directionalLightRef = useRef<THREE.DirectionalLight>(null);
  const initialCameraPosition = useRef(new THREE.Vector3(-0.2, 1.5, -6.5));
  const initialTargetPosition = useRef(new THREE.Vector3(-0.2, 1.5, -8));
  const lastPositions = useRef<{ [key: string]: THREE.Vector3 }>({});
  const lastRotations = useRef<{ [key: string]: THREE.Euler }>({});

  const envMapIntensity = 1;
  const roomSize = { width: 10, depth: 10, height: 5 };
  const roomPosition = new THREE.Vector3(3, -0.5, -4);
  const sceneRotation = new THREE.Euler(...rotation);

  const clampPosition = useMemo(() => {
    const objects = [
      ...components.map(c => ({
        position: c.position,
        size: { 
          width: c.dimensions?.x ?? 0, 
          depth: c.dimensions?.z ?? 0, 
          height: c.dimensions?.y ?? 0 
        }
      })),
      {
        position: new THREE.Vector3(0, 0, -7.8), // Table position
        size: { width: 3.5, depth: 1.1, height: 1 }
      },
      {
        position: new THREE.Vector3(6.5, 0, -8), // Bed position (adjust as needed)
        size: { width: 3, depth: 6.15, height: 0.6 } // Typical bed dimensions (adjust as needed)
      },
      {
        position: new THREE.Vector3(-2, 0, -7.2), 
        size: { width: 2.6, depth: 4, height: 3 } 
      }
    ];
    return createPositionClamper(roomPosition, roomSize, sceneRotation, objects);
  }, [roomPosition, roomSize, sceneRotation, components]);

  const groupRef = useRef<THREE.Group>(null);

  const transformControlsRef = useRef<TransformControlsImpl>(null);

  useEffect(() => {
    if (resetCamera && controlMode) {
      camera.position.set(-0.2, 1.5, -6.5);
      orbitControlsRef.current?.target.set(-0.2, 1.5, -8);
      orbitControlsRef.current?.update();
      onResetComplete();
    }
  }, [resetCamera, onResetComplete, camera]);

  const setupShadows = () => {
    gl.shadowMap.enabled = true;
    gl.shadowMap.type = THREE.PCFSoftShadowMap;
    
    scene.traverse((object) => {
      if (object instanceof THREE.Mesh) {
        object.castShadow = true;
        object.receiveShadow = true;
      }
    });

    if (directionalLightRef.current) {
      directionalLightRef.current.shadow.mapSize.width = 4096;
      directionalLightRef.current.shadow.mapSize.height = 4096;
      directionalLightRef.current.shadow.camera.near = 0.5;
      directionalLightRef.current.shadow.camera.far = 500;
      directionalLightRef.current.shadow.camera.left = -20;
      directionalLightRef.current.shadow.camera.right = 20;
      directionalLightRef.current.shadow.camera.top = 20;
      directionalLightRef.current.shadow.camera.bottom = -20;
      directionalLightRef.current.shadow.bias = -0.0001;
      directionalLightRef.current.shadow.normalBias = 0.02;
    }
  };

  useEffect(() => {
    if (orbitControlsRef.current) {
      const controls = orbitControlsRef.current;

      controls.minDistance = 0.5;
      controls.maxDistance = Math.min(roomSize.width, roomSize.depth, roomSize.height) * 0.8;

      controls.addEventListener('change', () => {
        const cameraPosition = camera.position.clone();
        const clampedPosition = clampPosition(cameraPosition);
        
        if (!cameraPosition.equals(clampedPosition)) {
          camera.position.copy(clampedPosition);
          controls.target.copy(clampPosition(controls.target));
        }

        initialCameraPosition.current.copy(camera.position);
        initialTargetPosition.current.copy(controls.target);
      });
    }
  }, [camera, roomSize, roomPosition, rotation, clampPosition]);

  const updateAllMaterials = React.useCallback(() => {
    scene.traverse((child) => {
      if (
        child instanceof THREE.Mesh &&
        child.material instanceof THREE.MeshStandardMaterial
      ) {
        if (!child.userData.originalMaterial) {
          child.userData.originalMaterial = child.material.clone();
        }
        child.material.envMapIntensity = envMapIntensity;
        child.material.needsUpdate = true;
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
  }, [scene, envMapIntensity]);

  const applyRealisticMaterial = (obj: THREE.Object3D) => {
    obj.traverse((child) => {
      if (child instanceof THREE.Mesh && child.material) {
        if (Array.isArray(child.material)) {
          child.material = child.material.map(updateMaterial);
        } else {
          child.material = updateMaterial(child.material);
        }
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
  };

  useEffect(() => {
    components.forEach(component => {
      if (component.obj) {
        applyRealisticMaterial(component.obj);
      }
    });
    updateAllMaterials();
    setupShadows();
  }, [components, materialType, applyRealisticMaterial, updateAllMaterials, setupShadows]);

  const updateMaterial = (originalMaterial: THREE.Material): THREE.Material => {
    let newMaterial: THREE.Material;
  
    const getOriginalColor = (material: THREE.Material): THREE.Color => {
      if (material instanceof THREE.MeshStandardMaterial || material instanceof THREE.MeshPhysicalMaterial) {
        return material.color;
      }
      return new THREE.Color(0xffffff);
    };
  
    const originalColor = getOriginalColor(originalMaterial);
  
    switch (materialType) {
      case 'rubber':
        newMaterial = new THREE.MeshStandardMaterial({
          color: originalColor,
          roughness: 0.9,
          metalness: 0.1
        });
        break;
      case 'window':
        newMaterial = new THREE.MeshPhysicalMaterial({
          color: originalColor,
          roughness: 0,
          clearcoat: 0.1,
          transmission: 0.9,
          opacity: 0.5
        });
        break;
      case 'coat':
        newMaterial = new THREE.MeshStandardMaterial({
          color: originalColor,
          roughness: 0.2,
          metalness: 0.8,
          envMapIntensity: 1
        });
        break;
      case 'paint':
      default:
        newMaterial = new THREE.MeshStandardMaterial({
          color: originalColor,
          roughness: 0.5,
          metalness: 0.5,
          envMapIntensity: 1
        });
        break;
    }
  
    // Adjust material properties for very dark colors
    if (originalColor.r + originalColor.g + originalColor.b < 0.15) {
      if (newMaterial instanceof THREE.MeshStandardMaterial) {
        newMaterial.roughness = 0.8;
        newMaterial.metalness = 0.2;
        newMaterial.envMapIntensity = 1;
      }
    }
  
    // Copy other relevant properties from the original material
    if (originalMaterial instanceof THREE.MeshStandardMaterial) {
      if (newMaterial instanceof THREE.MeshStandardMaterial || newMaterial instanceof THREE.MeshPhysicalMaterial) {
        newMaterial.map = originalMaterial.map;
        newMaterial.normalMap = originalMaterial.normalMap;
        newMaterial.roughnessMap = originalMaterial.roughnessMap;
        newMaterial.metalnessMap = originalMaterial.metalnessMap;
        newMaterial.emissive = originalMaterial.emissive;
        newMaterial.emissiveMap = originalMaterial.emissiveMap;
      }
    } else if (originalMaterial instanceof THREE.MeshPhysicalMaterial) {
      if (newMaterial instanceof THREE.MeshPhysicalMaterial) {
        newMaterial.map = originalMaterial.map;
        newMaterial.normalMap = originalMaterial.normalMap;
        newMaterial.roughnessMap = originalMaterial.roughnessMap;
        newMaterial.metalnessMap = originalMaterial.metalnessMap;
        newMaterial.emissive = originalMaterial.emissive;
        newMaterial.emissiveMap = originalMaterial.emissiveMap;
        newMaterial.clearcoat = originalMaterial.clearcoat;
        newMaterial.clearcoatMap = originalMaterial.clearcoatMap;
        newMaterial.clearcoatRoughness = originalMaterial.clearcoatRoughness;
        newMaterial.clearcoatRoughnessMap = originalMaterial.clearcoatRoughnessMap;
      }
    }
  
    return newMaterial;
  };

  const handleSelectComponent = (id: string) => {
    setSelectedComponent(prevId => prevId === id ? null : id);
  };

  const handleTransformChange = (id: string) => {
    //console.log(`Transform change triggered for component: ${id}`);
    const component = components.find(c => c.id === id);
    if (component && component.obj) {
      const newPosition = component.obj.position.clone();
      const newRotation = new THREE.Euler().setFromQuaternion(component.obj.quaternion);

      //console.log(`New position: ${newPosition.toArray()}`);
      //console.log(`New rotation: ${newRotation.toArray()}`);

      const oldPosition = lastPositions.current[id] || component.position.clone();
      const oldRotation = lastRotations.current[id] || component.rotation.clone();

      //console.log(`Old position: ${oldPosition.toArray()}`);
      //console.log(`Old rotation: ${oldRotation.toArray()}`);

      // Calculate the change in position and rotation
      const positionDelta = newPosition.clone().sub(oldPosition);
      const rotationDelta = new THREE.Euler(
        newRotation.x - oldRotation.x,
        newRotation.y - oldRotation.y,
        newRotation.z - oldRotation.z
      );

      //console.log(`Position delta: ${positionDelta.toArray()}`);
      //console.log(`Rotation delta: ${rotationDelta.toArray()}`);

      // Helper function to recursively update child components
      const updateChildComponents = (parentId: string, positionDelta: THREE.Vector3, rotationDelta: THREE.Euler) => {
        components.forEach(childComponent => {
          if (childComponent.parentId === parentId && childComponent.obj) {
            //onsole.log(`Updating child component: ${childComponent.id}`);
            //console.log(`Child's current position: ${childComponent.position.toArray()}`);
            //console.log(`Child's current rotation: ${childComponent.rotation.toArray()}`);

            // Apply the position delta
            const newChildPosition = childComponent.position.clone().add(positionDelta);
            childComponent.obj.position.copy(newChildPosition);
            childComponent.position.copy(newChildPosition);

            // Apply the rotation delta
            const newChildRotation = new THREE.Euler(
              childComponent.rotation.x + rotationDelta.x,
              childComponent.rotation.y + rotationDelta.y,
              childComponent.rotation.z + rotationDelta.z
            );
            childComponent.obj.rotation.copy(newChildRotation);
            childComponent.rotation.copy(newChildRotation);

            //console.log(`Child's new position: ${childComponent.position.toArray()}`);
            //console.log(`Child's new rotation: ${childComponent.rotation.toArray()}`);

            // Update last positions and rotations for the child
            lastPositions.current[childComponent.id] = newChildPosition;
            lastRotations.current[childComponent.id] = newChildRotation;

            // Recursive call for any children of this child component
            updateChildComponents(childComponent.id, positionDelta, rotationDelta);
          }
        });
      };

      // If the moved component is a case or motherboard, update all children recursively
      if (component.category.toLowerCase() === 'case' || component.category.toLowerCase() === 'motherboard') {
        //console.log(`Updating children for ${component.category}`);
        updateChildComponents(component.id, positionDelta, rotationDelta);
      }

      // Update the component's position and rotation
      component.position.copy(newPosition);
      component.rotation.copy(newRotation);

      // Update last positions and rotations
      lastPositions.current[id] = newPosition;
      lastRotations.current[id] = newRotation;

      // Update the PC state
      setPc(prevPc => {
        const updateComponentInPc = (obj: any, path: string[]): any => {
          if (path.length === 0) {
            return {
              ...obj,
              position: newPosition,
              rotation: newRotation,
            };
          }
          
          const [current, ...rest] = path;
          return {
            ...obj,
            [current]: updateComponentInPc(obj[current] ?? {}, rest),
          };
        };
      
        let updatedPc = { ...prevPc };
        const categoryPath = component.category.toLowerCase();
        
        if (component.category.toLowerCase() === 'case') {
          updatedPc.case = updateComponentInPc(updatedPc.case, []);
        } else if (component.category.toLowerCase() === 'motherboard' || component.category.toLowerCase() === 'psu') {
          updatedPc = updateComponentInPc(updatedPc, ['case', categoryPath]);
        } else {
          updatedPc = updateComponentInPc(updatedPc, ['case', 'motherboard', categoryPath]);
        }
      
        return updatedPc;
      });
  
      // Call the onUpdateComponent prop to notify parent components
      onUpdateComponent(id, newPosition, newRotation);
    }
  };

  const toggleTransformMode = () => {
    setTransformMode(prevMode => prevMode === 'translate' ? 'rotate' : 'translate');
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'r') {
        toggleTransformMode();
      } else if (event.key === 'f') {
        toggleControlMode();
      } else if (event.key === 'Escape' && controlMode === 'firstPerson') {
        setControlMode('orbit');
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [controlMode]);

  useEffect(() => {
    if (showInitialTooltip) {
      const timer = setTimeout(() => {
        setShowInitialTooltip(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showInitialTooltip]);

  const toggleControlMode = () => {
    setControlMode(prevMode => {
      const newMode = prevMode === 'orbit' ? 'firstPerson' : 'orbit';
      if (newMode === 'firstPerson') {
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 5000);
      }
      return newMode;
    });
  };
  useFrame(() => {
    components.forEach(component => {
      if (component.obj) {
        component.obj.position.lerp(component.position, 0.1);
        const targetQuaternion = new THREE.Quaternion().setFromEuler(component.rotation);
        component.obj.quaternion.slerp(targetQuaternion, 0.1);
      }
    });

    if (directionalLightRef.current) {
      directionalLightRef.current.position.copy(lightingOptions.directionalLightPosition);
      directionalLightRef.current.intensity = lightingOptions.directionalLightIntensity;
      directionalLightRef.current.color.set(lightingOptions.directionalLightColor);
    }
  });

  {/* <CustomObject3D
  filePath:"/3d/desk/Unique_Desk.fbx",
  fileType:"fbx",
  //texturePath:"/3d/desk/gaming_table_color.png",
  initialPosition:new THREE.Vector3(-1.98, 0.22, -7.98),
  initialRotation:new THREE.Euler(0, 0, 0),
  initialScale:new THREE.Vector3(0.02, 0.02, 0.02),
  //dimensions:{ width: 1, height: 1, depth: 1 },

/>
<CustomObject3D
  filePath:"/3d/desk/RaisingDeskCGT.fbx",
  fileType:"fbx",
  texturePath:"/3d/desk/raising_table_color.png",
  initialPosition:new THREE.Vector3(0, -0.5, -7.98)
  initialRotation:new THREE.Euler(-Math.PI/2, 0, -Math.PI/2),
  initialScale:new THREE.Vector3(0.02, 0.02, 0.02),
  //dimensions= width: 1, height: 1, depth: 1 },
/> 
    {
      filePath: "/3d/desk-L/L_Desk_Option_2.stl",
      fileType: "stl",
      texturePath: "/3d/desk/gaming_table_color.png",
      initialPosition: new THREE.Vector3(-1.98, 0.89, -8.98),
      initialRotation: new THREE.Euler(0, Math.PI/2, 0),
      initialScale: new THREE.Vector3(0.02, 0.02, 0.02),
      //dimensions: { width: 1, height: 1, depth: 1 },
    },
    {
      filePath: "/3d/ender-3-model/EnderMini.stl",
      fileType: "stl",
      texturePath: "/textures/d1.jpg",
      initialPosition: new THREE.Vector3(-1.1, 1, -9.9),
      initialRotation: new THREE.Euler(0, 0, Math.PI),
      initialScale: new THREE.Vector3(0.005, 0.005, 0.005),
      //dimensions: { width: 1, height: 1, depth: 1 },
    }
*/}
  const staticObjects = useMemo(() => [
    {
      filePath:"/3d/desk/Unique_Desk.fbx",
      fileType:"fbx",
      texturePath:"/3d/desk/gaming_table_color.png",
      initialPosition:new THREE.Vector3(-1.90, 0.22, -8.1),
      initialRotation:new THREE.Euler(0, 0, 0),
      initialScale:new THREE.Vector3(0.02, 0.02, 0.02),
      //dimensions:{ width: 1, height: 1, depth: 1 },
    },
    {
      filePath:"/3d/monitor/49_inch_monitor.fbx",
      fileType:"fbx",
      //texturePath:"/3d/desk/gaming_table_color.png",
      initialPosition:new THREE.Vector3(0, 0.93, -8.4),
      initialRotation:new THREE.Euler(0, 0, 0),
      initialScale:new THREE.Vector3(0.2, 0.2, 0.2),
      //dimensions:{ width: 1, height: 1, depth: 1 },
    },
    {
      filePath: "/3d/chair/gaming_chair.fbx",
      fileType: "fbx",
      initialPosition: new THREE.Vector3(-1, 0.22, -7),
      initialRotation: new THREE.Euler(0, Math.PI/1.5, 0),
      initialScale: new THREE.Vector3(0.003, 0.003, 0.003),
      //dimensions: { width: 1, height: 1, depth: 1 },
    },
    {
      filePath: "/3d/ender-3-model/EnderMini.stl",
      fileType: "stl",
      initialPosition: new THREE.Vector3(-3.2, 0.98, -8.7),
      initialRotation: new THREE.Euler(0, Math.PI/2, Math.PI),
      initialScale: new THREE.Vector3(0.005, 0.005, 0.005),
      //dimensions: { width: 1, height: 1, depth: 1 },
    },
    {
      filePath: "/3d/window/Window_V2_Holes_NoLight.fbx",
      fileType: "fbx",
      initialPosition: new THREE.Vector3(3, 1.0, -8.95),
      initialRotation: new THREE.Euler(0, -Math.PI/2, 0),
      initialScale: new THREE.Vector3(0.015, 0.015, 0.015),
      //dimensions: { width: 1, height: 1, depth: 1 },
    },
    {
      filePath: "/3d/blinds/3dm-blinds.3ds",
      fileType: "3ds",
      texturePath: "/textures/floor/f3.jpg",
      initialPosition: new THREE.Vector3(3, 1.2, -8.85),
      initialRotation: new THREE.Euler(-Math.PI/2, 0, 0),
      initialScale: new THREE.Vector3(0.06, 0.04, 0.050),
      //dimensions: { width: 1, height: 1, depth: 1 },
    },
    {
      filePath: "/3d/bed/Big_Bed.fbx",
      fileType: "fbx",
      initialPosition: new THREE.Vector3(6.5, -0.51, -6.95),
      initialRotation: new THREE.Euler(0, 0, 0),
      initialScale: new THREE.Vector3(0.0002, 0.0002, 0.0002),
      //dimensions: { width: 1, height: 1, depth: 1 }6.5, 0, -8,
    },
    {
      filePath: "/3d/lamp/large_lamp.fbx",
      fileType: "fbx",
      texturePath: "/textures/floor/f3.jpg",
      initialPosition: new THREE.Vector3(3, 3.16, -3.5),
      initialRotation: new THREE.Euler(0, Math.PI, 0),
      initialScale: new THREE.Vector3(0.0001, 0.0001, 0.0001),
      //dimensions: { width: 1, height: 1, depth: 1 },
    },
    {
      filePath: "/3d/door/Door.fbx",
      fileType: "fbx",
      texturePath: "/textures/floor/f3.jpg",
      initialPosition: new THREE.Vector3(1, -0.5, 1.03),
      initialRotation: new THREE.Euler(0, Math.PI, 0),
      initialScale: new THREE.Vector3(0.023, 0.017, 0.017),
      //dimensions: { width: 1, height: 1, depth: 1 },
    },
  ], []);
  return (
    <>
      <PerspectiveCamera makeDefault position={initialCameraPosition.current} fov={75} />
      
      <ambientLight intensity={lightingOptions.ambientLightIntensity} color={lightingOptions.ambientLightColor} />

      { <directionalLight
        ref={directionalLightRef}
        position={lightingOptions.directionalLightPosition}
        intensity={lightingOptions.directionalLightIntensity}
        color={lightingOptions.directionalLightColor}
        castShadow
      >
      {/* <mesh position={lightingOptions.directionalLightPosition}>
        <sphereGeometry args={[0.3, 16, 16]} />
        <meshStandardMaterial color="yellow" />
      </mesh> */}
      </directionalLight> }
      

      <pointLight 
        position={[3, 3.2, -3.5]} 
        intensity={50} 
        color="#FFFFFF" 
        castShadow
        shadow-mapSize={[4096, 4096]}
      >
        {/* <mesh>
          <sphereGeometry args={[0.05, 16, 16]} />
          <meshStandardMaterial color="#FFF5E0" />
        </mesh> */}
      </pointLight>
      <pointLight 
        position={[-0.2, 1.5, -7]} 
        intensity={5} 
        color="#FFFFFF" 
        castShadow
        shadow-mapSize={[1024, 1024]}
      >
        {/* <mesh>
          <sphereGeometry args={[0.05, 16, 16]} />
          <meshStandardMaterial color="#FFF5E0" />
        </mesh> */}
      </pointLight>

      {React.useMemo(() => (
        <EnvironmentLoader
          imagePath="/textures/environments/sunset_jhbcentral_4k_cropped_compressed.png"
          width={2952}
          height={2916}
          rotation={Math.PI * 1.14}
          includeLighting={false}
          sphereRadius={1000}
          debug={false}
          verticalScale={1.14}
          horizontalScale={1}
        />
      ), [])}
  <group ref={groupRef} rotation={rotation}>
    <Suspense fallback={null}>
      <LazyRoom3D
        width={roomSize.width}
        depth={roomSize.depth}
        height={roomSize.height}
        windowWidth={2.5}
        windowHeight={2.3}
        position={[roomPosition.x, roomPosition.y, roomPosition.z]} // Control the initial position of the room
        //rotation={[0, -Math.PI / 1.7, 0]} // This will rotate the room 45 degrees around the y-axis
        baseboardHeight={0.2}
        crownMoldingHeight={0.1}
      />
    </Suspense>
    {staticObjects.map((obj, index) => (
      <CustomObject3D
        key={`static-object-${index}`}
        filePath={obj.filePath || ''}
        fileType={(obj.fileType as "fbx" | "stl" | "3ds" | "obj") || "obj"}
        texturePath={obj.texturePath || ''}
        initialPosition={obj.initialPosition || new THREE.Vector3()}
        initialRotation={obj.initialRotation || new THREE.Euler()}
        initialScale={obj.initialScale || new THREE.Vector3(1, 1, 1)}
      />
    ))}

      {components.map((component) => (
        component.obj && (
          <group key={component.id}>
            <primitive 
              object={component.obj} 
              position={component.position}
              rotation={component.rotation}
              onClick={(e: { stopPropagation: () => void; }) => {
                e.stopPropagation();
                handleSelectComponent(component.id);
              }}
            />
            {selectedComponent === component.id && (
              <TransformControls
                ref={transformControlsRef}
                object={component.obj}
                mode={transformMode}
                space="local"
                onObjectChange={() => {
                  if (component.obj) {
                    handleTransformChange(component.id);
                    onUpdateComponent(component.id, component.obj.position, new THREE.Euler().setFromQuaternion(component.obj.quaternion));
                  }
                }}
                onMouseDown={() => {
                  if (orbitControlsRef.current) {
                    orbitControlsRef.current.enabled = false;
                  }
                }}
                onMouseUp={() => {
                  if (orbitControlsRef.current) {
                    orbitControlsRef.current.enabled = true;
                  }
                }}
              />
            )}
          </group>
        )
      ))}
    </group>
    {controlMode === 'orbit' ? (
        <OrbitControls
          ref={orbitControlsRef}
          enableDamping
          dampingFactor={0.25}
          maxDistance={Math.min(roomSize.width, roomSize.depth, roomSize.height) * 0.8}
          minDistance={0.5}
          target={initialTargetPosition.current}
          onChange={() => {
            if(orbitControlsRef.current){
            initialCameraPosition.current.copy(camera.position);
            initialTargetPosition.current.copy(orbitControlsRef.current.target);
          }
          }}
        />
      ) : (
        <FirstPersonPlayer clampPosition={clampPosition} />
      )}
      
      <Tooltip showTooltip={showInitialTooltip} message="              Press F and double click the 
      .            screen to enter first-person mode and walk around." />

      <Tooltip showTooltip={showTooltip} message="Use WASD to move, Space to jump, and mouse to look around.
        Press ESC to exit first-person mode." />

      {/* <Perf 
        position="top-right"
        antialias={false}
        deepAnalyze={false}  // Changed to false
        matrixUpdate={false}  // Changed to false
        overClock={false}  // Changed to false
        passes={false}  // Changed to false
        showGraph={false}  // Changed to false
        chart={{
          cpu: true,
          fps: true,
          memory: true
        }}
        minimal={false}  // Added minimal mode
        logsPerSecond={1}  // Reduced update frequency
      /> */}
    </>
  );
};

export default RealisticScene;