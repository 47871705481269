import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import styles from './PartSelectionModal.module.css';
import { PCComponent } from '../../../types/types';
import { assetLoader } from '../../../services/assetLoader';
import { priceService } from '../../../services/priceService';
import { Grid, List } from 'lucide-react';
import { useProductPreferences } from '../../../hooks/useProductPreferences';

interface PartSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  parts: PCComponent[];
  onSelect: (component: PCComponent) => void;
  partType: string;
}

type SortOption = 'Release Date (Newest)' | 'Release Date (Oldest)' | 'Highest Rated' | 'Lowest Rated' | 'Highest Price' | 'Lowest Price';

interface StarRatingProps {
  rating: number;
  onRate: (rating: number) => void;
  ratingCount: number;
}

const StarRating: React.FC<StarRatingProps> = ({ rating, onRate, ratingCount }) => {
  const [hoverRating, setHoverRating] = useState(0);

  return (
    <div className={styles.starRating} onClick={(e) => e.stopPropagation()}>
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          className={`${styles.star} ${
            (hoverRating || rating) >= star ? styles.filled : ''
          }`}
          onMouseEnter={() => setHoverRating(star)}
          onMouseLeave={() => setHoverRating(0)}
          onClick={() => onRate(star)}
        >
          ★
        </span>
      ))}
      <span className={styles.ratingInfo}>
        ({ratingCount} {ratingCount === 1 ? 'rating' : 'ratings'})
      </span>
    </div>
  );
};

const PartSelectionModal: React.FC<PartSelectionModalProps> = ({
  isOpen,
  onClose,
  parts,
  onSelect,
  partType,
}) => {
  const {
    itemsPerPage,
    setItemsPerPage,
    itemsPerRow,
    setItemsPerRow,
    useInfiniteScroll,
    setUseInfiniteScroll
  } = useProductPreferences();

  const [sortBy, setSortBy] = useState<SortOption>('Release Date (Newest)');
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [modelAvailability, setModelAvailability] = useState<Record<string, boolean>>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const checkModelAvailability = async () => {
      const assetIds = parts.map(part => `${part.category}/${part.modelName}`);
      const availability = await assetLoader.checkAssetsAvailability(assetIds);
      setModelAvailability(
        Object.fromEntries(parts.map(part => [part.id, availability[`${part.category}/${part.modelName}`]]))
      );
    };
  
    if (isOpen) {
      checkModelAvailability();
      setCurrentPage(1);
    }
  }, [isOpen, parts]);
  
  const filterOptions = useMemo(() => {
    const options: Record<string, Set<string>> = {};
    parts.forEach(part => {
      Object.entries(part).forEach(([key, value]) => {
        if (typeof value === 'string' || typeof value === 'number') {
          if (!options[key]) options[key] = new Set();
          options[key].add(String(value));
        }
      });
    });
    return options;
  }, [parts]);

  const handleRate = useCallback((partId: string, rating: number) => {
    priceService.addRating(partId, rating);
  }, []);

  const filteredAndSortedParts = useMemo(() => {
    const sortedParts = parts
      .filter(part => {
        // Apply search filter
        const searchMatch = searchTerm === '' || 
          part.modelName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          part.category.toLowerCase().includes(searchTerm.toLowerCase());

        // Apply selected filters
        const filterMatch = Object.entries(filters).every(([key, value]) => 
          value === '' || String(part[key as keyof PCComponent]) === value
        );

        return searchMatch && filterMatch;
      })
      .sort((a, b) => {
        const aPricing = priceService.getPricing(a.id);
        const bPricing = priceService.getPricing(b.id);

        switch (sortBy) {
          case 'Release Date (Newest)':
            return Math.random() - 0.5;
          case 'Release Date (Oldest)':
            return Math.random() - 0.5;
          case 'Highest Rated':
            return parseFloat(bPricing.rating) - parseFloat(aPricing.rating);
          case 'Lowest Rated':
            return parseFloat(aPricing.rating) - parseFloat(bPricing.rating);
          case 'Highest Price':
            return parseFloat(bPricing.price) - parseFloat(aPricing.price);
          case 'Lowest Price':
            return parseFloat(aPricing.price) - parseFloat(bPricing.price);
          default:
            return 0;
        }
      });

    if (sortBy === 'Lowest Rated') {
      sortedParts.slice(0, 3).forEach(part => {
        part.isLowestRated = true;
      });
    }

    return sortedParts;
  }, [parts, sortBy, filters, searchTerm]);

  // Calculate pagination
  const paginatedParts = useMemo(() => {
    if (useInfiniteScroll) {
      const endIndex = currentPage * itemsPerPage;
      const slicedParts = filteredAndSortedParts.slice(0, endIndex);
      setHasMore(endIndex < filteredAndSortedParts.length);
      return slicedParts;
    } else {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return filteredAndSortedParts.slice(startIndex, endIndex);
    }
  }, [currentPage, itemsPerPage, useInfiniteScroll, filteredAndSortedParts]);

  // Setup infinite scroll observer
  useEffect(() => {
    if (!useInfiniteScroll) return;

    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setCurrentPage(prev => prev + 1);
      }
    }, options);

    if (lastElementRef.current) {
      observer.current.observe(lastElementRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [hasMore, useInfiniteScroll]);

  const totalPages = Math.ceil(filteredAndSortedParts.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleFilterChange = useCallback((key: string, value: string) => {
    setFilters(prev => ({...prev, [key]: value}));
    setCurrentPage(1);
  }, []);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h2 className={styles.modalTitle}>Select {partType}</h2>
        <div className={styles.modalContent}>
          <div className={styles.controls}>
            <div className={styles.searchAndDisplay}>
              <input 
                type="text" 
                placeholder="Search components..." 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
              />
              <div className={styles.displayControls}>
                <div className={styles.controlGroup}>
                  <Grid className="w-5 h-5 text-gray-400" />
                  <select
                    value={itemsPerRow}
                    onChange={(e) => setItemsPerRow(Number(e.target.value))}
                    className={styles.controlSelect}
                  >
                    <option value={3}>3 per row</option>
                    <option value={4}>4 per row</option>
                    <option value={6}>6 per row</option>
                  </select>
                </div>
                <div className={styles.controlGroup}>
                  <List className="w-5 h-5 text-gray-400" />
                  <select
                    value={itemsPerPage}
                    onChange={(e) => {
                      setItemsPerPage(Number(e.target.value));
                      setCurrentPage(1);
                    }}
                    className={styles.controlSelect}
                  >
                    <option value={12}>12 per page</option>
                    <option value={24}>24 per page</option>
                    <option value={48}>48 per page</option>
                  </select>
                </div>
                <div className={styles.controlGroup}>
                  <label className="text-sm text-gray-400">Infinite scroll</label>
                  <input
                    type="checkbox"
                    checked={useInfiniteScroll}
                    onChange={() => {
                      setUseInfiniteScroll(!useInfiniteScroll);
                      setCurrentPage(1);
                    }}
                    className={styles.checkbox}
                  />
                </div>
              </div>
            </div>
            <div className={styles.sortControls}>
              <select 
                value={sortBy} 
                onChange={(e) => setSortBy(e.target.value as SortOption)}
                className={styles.sortSelect}
              >
                <option value="Release Date (Newest)">Release Date (Newest)</option>
                <option value="Release Date (Oldest)">Release Date (Oldest)</option>
                <option value="Highest Rated">Highest Rated</option>
                <option value="Lowest Rated">Lowest Rated</option>
                <option value="Highest Price">Highest Price</option>
                <option value="Lowest Price">Lowest Price</option>
              </select>
            </div>
          </div>
          <div className={styles.filters}>
            {Object.entries(filterOptions).map(([key, values]) => (
              <div key={key} className={styles.filterGroup}>
                <label>{key}</label>
                <select
                  value={filters[key] || ''}
                  onChange={(e) => handleFilterChange(key, e.target.value)}
                >
                  <option value="">All</option>
                  {Array.from(values).map(value => (
                    <option key={value} value={value}>{value}</option>
                  ))}
                </select>
              </div>
            ))}
          </div>
          <div 
            className={styles.partsGrid}
            style={{
              gridTemplateColumns: `repeat(${itemsPerRow}, minmax(0, 1fr))`
            }}
          >
            {paginatedParts.map((part) => {
              const pricing = priceService.getPricing(part.id);
              return (
                <div 
                  key={part.id} 
                  className={`${styles.partCard} ${!modelAvailability[part.id] ? styles.unavailable : ''} ${part.isLowestRated ? styles.lowestRated : ''}`} 
                  onClick={() => modelAvailability[part.id] && onSelect(part)}
                >
                  <div className={styles.imageContainer}>
                    <img
                      src={`/images/${part.category}/${part.modelName}.png`}
                      onError={(e) => {
                        const img = e.currentTarget;
                        if (!img.src.endsWith('.jpg')) {
                          img.src = `/images/${part.category}/${part.modelName}.jpg`;
                        } else {
                          img.style.display = 'none';
                        }
                      }}
                      alt={part.modelName}
                      className={styles.partImage}
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  {!modelAvailability[part.id] && (
                    <div className={styles.unavailableOverlay}>
                      <span>3D Model Unavailable</span>
                    </div>
                  )}
                  <div className={styles.partInfo}>
                    <h3 className={styles.partName}>{part.modelName}</h3>
                    <p className={styles.partCategory}>{part.category}</p>
                    <p className={styles.partPrice}>${parseFloat(pricing.price).toFixed(2)}</p>
                    <div className={styles.partRating}>
                      <StarRating 
                        rating={parseFloat(pricing.rating)} 
                        onRate={(rating) => handleRate(part.id, rating)}
                        ratingCount={pricing.ratingCount}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            {useInfiniteScroll && hasMore && (
              <div ref={lastElementRef} className={styles.loadingMore}>
                <div className={styles.spinner}></div>
              </div>
            )}
          </div>
          {!useInfiniteScroll && filteredAndSortedParts.length > 0 && (
            <div className={styles.pagination}>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={styles.pageButton}
              >
                Previous
              </button>
              
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter(page => {
                  const distance = Math.abs(page - currentPage);
                  return distance === 0 || distance === 1 || page === 1 || page === totalPages;
                })
                .map((page, index, array) => (
                  <React.Fragment key={page}>
                    {index > 0 && array[index - 1] !== page - 1 && (
                      <span className={styles.ellipsis}>...</span>
                    )}
                    <button
                      onClick={() => handlePageChange(page)}
                      className={`${styles.pageButton} ${
                        currentPage === page ? styles.activePage : ''
                      }`}
                    >
                      {page}
                    </button>
                  </React.Fragment>
                ))}
              
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={styles.pageButton}
              >
                Next
              </button>
            </div>
          )}
        </div>
        <button className={styles.closeButton} onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default PartSelectionModal;
