import React, { useRef, useEffect, useState, useMemo, Suspense, useCallback } from 'react';
import * as THREE from 'three';
import { Perf } from 'r3f-perf';
import { TransformControls as TransformControlsImpl, OrbitControls as OrbitControlsImpl } from 'three-stdlib'
import { useThree, useFrame } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, TransformControls } from '@react-three/drei';
import { PCComponent, PC, LightingOptions } from '../../types/types';
import CustomObject3D from './components/CustomObject3D';


interface LowEndSceneProps {
  components: PCComponent[];
  onUpdateComponent: (id: string, newPosition: THREE.Vector3, newRotation: THREE.Euler) => void;
  materialType: string;
  lightingOptions: LightingOptions;
  pc: PC,
  setPc: React.Dispatch<React.SetStateAction<PC>>
  resetCamera: boolean;
  onResetComplete: () => void;
}

const LowEndScene: React.FC<LowEndSceneProps> = ({ 
  components, 
  onUpdateComponent, 
  materialType,
  lightingOptions,
  pc,
  setPc,
  resetCamera,
  onResetComplete,
}) => {
  const { scene, camera, gl } = useThree();
  const [selectedComponent, setSelectedComponent] = useState<string | null>(null);
  const [controlsPosition, setControlsPosition] = useState<THREE.Vector3 | null>(null);
  const [transformMode, setTransformMode] = useState<'translate' | 'rotate'>('translate');
  const orbitControlsRef = useRef<OrbitControlsImpl>(null);
  const [controlMode, setControlMode] = useState<'orbit' | 'firstPerson'>('orbit');
  const [showTooltip, setShowTooltip] = useState(false);
  const directionalLightRef = useRef<THREE.DirectionalLight>(null);
  const initialCameraPosition = useRef(new THREE.Vector3(-0.2, 1.5, -6.5));
  const initialTargetPosition = useRef(new THREE.Vector3(-0.2, 1.5, -8));
  const lastPositions = useRef<{ [key: string]: THREE.Vector3 }>({});
  const lastRotations = useRef<{ [key: string]: THREE.Euler }>({});

  const groupRef = useRef<THREE.Group>(null);

  const transformControlsRef = useRef<TransformControlsImpl>(null);

  useEffect(() => {
    if (orbitControlsRef.current) {
      orbitControlsRef.current.target.copy(initialTargetPosition.current);
      orbitControlsRef.current.update();
    }
  }, []);
  
  useEffect(() => {
    if (resetCamera && orbitControlsRef.current) {
      camera.position.set(-0.2, 1.5, -6.5);
      orbitControlsRef.current.target.set(-0.2, 1.5, -8);
      orbitControlsRef.current.update();
      initialCameraPosition.current.copy(camera.position);
      initialTargetPosition.current.copy(orbitControlsRef.current.target);
      onResetComplete();
    }
  }, [resetCamera, onResetComplete, camera]);

  const setupShadows = () => {
    gl.shadowMap.enabled = true;
    gl.shadowMap.type = THREE.PCFSoftShadowMap;
    
    scene.traverse((object) => {
      if (object instanceof THREE.Mesh) {
        object.castShadow = true;
        object.receiveShadow = true;
      }
    });
  };

  const updateAllMaterials = React.useCallback(() => {
    scene.traverse((child) => {
      if (
        child instanceof THREE.Mesh &&
        child.material instanceof THREE.MeshStandardMaterial
      ) {
        if (!child.userData.originalMaterial) {
          child.userData.originalMaterial = child.material.clone();
        }
        child.material.needsUpdate = true;
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
  }, [scene]);

  const applyRealisticMaterial = (obj: THREE.Object3D) => {
    obj.traverse((child) => {
      if (child instanceof THREE.Mesh && child.material) {
        if (Array.isArray(child.material)) {
          child.material = child.material.map(updateMaterial);
        } else {
          child.material = updateMaterial(child.material);
        }
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
  };

  useEffect(() => {
    components.forEach(component => {
      if (component.obj) {
        applyRealisticMaterial(component.obj);
      }
    });
    updateAllMaterials();
    setupShadows();
  }, [components, materialType, applyRealisticMaterial, updateAllMaterials, setupShadows]);

  const updateMaterial = (originalMaterial: THREE.Material): THREE.Material => {
    let newMaterial: THREE.Material;
  
    const getOriginalColor = (material: THREE.Material): THREE.Color => {
      if (material instanceof THREE.MeshStandardMaterial || material instanceof THREE.MeshPhysicalMaterial) {
        return material.color;
      }
      return new THREE.Color(0xffffff);
    };
  
    const originalColor = getOriginalColor(originalMaterial);
  
    switch (materialType) {
      case 'rubber':
        newMaterial = new THREE.MeshStandardMaterial({
          color: originalColor,
          roughness: 0.9,
          metalness: 0.1
        });
        break;
      case 'window':
        newMaterial = new THREE.MeshPhysicalMaterial({
          color: originalColor,
          roughness: 0,
          clearcoat: 0.1,
          transmission: 0.9,
          opacity: 0.5
        });
        break;
      case 'coat':
        newMaterial = new THREE.MeshStandardMaterial({
          color: originalColor,
          roughness: 0.2,
          metalness: 0.8,
          envMapIntensity: 1
        });
        break;
      case 'paint':
      default:
        newMaterial = new THREE.MeshStandardMaterial({
          color: originalColor,
          roughness: 0.5,
          metalness: 0.5,
          envMapIntensity: 1
        });
        break;
    }
  
    // Adjust material properties for very dark colors
    if (originalColor.r + originalColor.g + originalColor.b < 0.15) {
      if (newMaterial instanceof THREE.MeshStandardMaterial) {
        newMaterial.roughness = 0.8;
        newMaterial.metalness = 0.2;
        newMaterial.envMapIntensity = 1;
      }
    }
  
    // Copy other relevant properties from the original material
    if (originalMaterial instanceof THREE.MeshStandardMaterial) {
      if (newMaterial instanceof THREE.MeshStandardMaterial || newMaterial instanceof THREE.MeshPhysicalMaterial) {
        newMaterial.map = originalMaterial.map;
        newMaterial.normalMap = originalMaterial.normalMap;
        newMaterial.roughnessMap = originalMaterial.roughnessMap;
        newMaterial.metalnessMap = originalMaterial.metalnessMap;
        newMaterial.emissive = originalMaterial.emissive;
        newMaterial.emissiveMap = originalMaterial.emissiveMap;
      }
    } else if (originalMaterial instanceof THREE.MeshPhysicalMaterial) {
      if (newMaterial instanceof THREE.MeshPhysicalMaterial) {
        newMaterial.map = originalMaterial.map;
        newMaterial.normalMap = originalMaterial.normalMap;
        newMaterial.roughnessMap = originalMaterial.roughnessMap;
        newMaterial.metalnessMap = originalMaterial.metalnessMap;
        newMaterial.emissive = originalMaterial.emissive;
        newMaterial.emissiveMap = originalMaterial.emissiveMap;
        newMaterial.clearcoat = originalMaterial.clearcoat;
        newMaterial.clearcoatMap = originalMaterial.clearcoatMap;
        newMaterial.clearcoatRoughness = originalMaterial.clearcoatRoughness;
        newMaterial.clearcoatRoughnessMap = originalMaterial.clearcoatRoughnessMap;
      }
    }
  
    return newMaterial;
  };

  const handleSelectComponent = (id: string) => {
    setSelectedComponent(prevId => prevId === id ? null : id);
  };

  const handleTransformChange = (id: string) => {
    //console.log(`Transform change triggered for component: ${id}`);
    const component = components.find(c => c.id === id);
    if (component && component.obj) {
      const newPosition = component.obj.position.clone();
      const newRotation = new THREE.Euler().setFromQuaternion(component.obj.quaternion);

      //console.log(`New position: ${newPosition.toArray()}`);
      //console.log(`New rotation: ${newRotation.toArray()}`);

      const oldPosition = lastPositions.current[id] || component.position.clone();
      const oldRotation = lastRotations.current[id] || component.rotation.clone();

      //console.log(`Old position: ${oldPosition.toArray()}`);
      //console.log(`Old rotation: ${oldRotation.toArray()}`);

      // Calculate the change in position and rotation
      const positionDelta = newPosition.clone().sub(oldPosition);
      const rotationDelta = new THREE.Euler(
        newRotation.x - oldRotation.x,
        newRotation.y - oldRotation.y,
        newRotation.z - oldRotation.z
      );

      //console.log(`Position delta: ${positionDelta.toArray()}`);
      //console.log(`Rotation delta: ${rotationDelta.toArray()}`);

      // Helper function to recursively update child components
      const updateChildComponents = (parentId: string, positionDelta: THREE.Vector3, rotationDelta: THREE.Euler) => {
        components.forEach(childComponent => {
          if (childComponent.parentId === parentId && childComponent.obj) {
            //onsole.log(`Updating child component: ${childComponent.id}`);
            //console.log(`Child's current position: ${childComponent.position.toArray()}`);
            //console.log(`Child's current rotation: ${childComponent.rotation.toArray()}`);

            // Apply the position delta
            const newChildPosition = childComponent.position.clone().add(positionDelta);
            childComponent.obj.position.copy(newChildPosition);
            childComponent.position.copy(newChildPosition);

            // Apply the rotation delta
            const newChildRotation = new THREE.Euler(
              childComponent.rotation.x + rotationDelta.x,
              childComponent.rotation.y + rotationDelta.y,
              childComponent.rotation.z + rotationDelta.z
            );
            childComponent.obj.rotation.copy(newChildRotation);
            childComponent.rotation.copy(newChildRotation);

            //console.log(`Child's new position: ${childComponent.position.toArray()}`);
            //console.log(`Child's new rotation: ${childComponent.rotation.toArray()}`);

            // Update last positions and rotations for the child
            lastPositions.current[childComponent.id] = newChildPosition;
            lastRotations.current[childComponent.id] = newChildRotation;

            // Recursive call for any children of this child component
            updateChildComponents(childComponent.id, positionDelta, rotationDelta);
          }
        });
      };

      // If the moved component is a case or motherboard, update all children recursively
      if (component.category.toLowerCase() === 'case' || component.category.toLowerCase() === 'motherboard') {
        //console.log(`Updating children for ${component.category}`);
        updateChildComponents(component.id, positionDelta, rotationDelta);
      }

      // Update the component's position and rotation
      component.position.copy(newPosition);
      component.rotation.copy(newRotation);

      // Update last positions and rotations
      lastPositions.current[id] = newPosition;
      lastRotations.current[id] = newRotation;

      // Update the PC state
      setPc(prevPc => {
        const updateComponentInPc = (obj: any, path: string[]): any => {
          if (path.length === 0) {
            return {
              ...obj,
              position: newPosition,
              rotation: newRotation,
            };
          }
          
          const [current, ...rest] = path;
          return {
            ...obj,
            [current]: updateComponentInPc(obj[current] ?? {}, rest),
          };
        };
      
        let updatedPc = { ...prevPc };
        const categoryPath = component.category.toLowerCase();
        
        if (component.category.toLowerCase() === 'case') {
          updatedPc.case = updateComponentInPc(updatedPc.case, []);
        } else if (component.category.toLowerCase() === 'motherboard' || component.category.toLowerCase() === 'psu') {
          updatedPc = updateComponentInPc(updatedPc, ['case', categoryPath]);
        } else {
          updatedPc = updateComponentInPc(updatedPc, ['case', 'motherboard', categoryPath]);
        }
      
        return updatedPc;
      });
  
      // Call the onUpdateComponent prop to notify parent components
      onUpdateComponent(id, newPosition, newRotation);
    }
  };

  useFrame(() => {
    components.forEach(component => {
      if (component.obj) {
        component.obj.position.lerp(component.position, 0.1);
        const targetQuaternion = new THREE.Quaternion().setFromEuler(component.rotation);
        component.obj.quaternion.slerp(targetQuaternion, 0.1);
      }
    });
  });
  const staticObjects = useMemo(() => [
    {
      filePath:"/3d/mat/mat_black.fbx",
      fileType:"fbx",
      initialPosition:new THREE.Vector3(-1.0, 0.925, -7.7),
      initialRotation:new THREE.Euler(0, 0, 0),
      initialScale:new THREE.Vector3(0.0222, 0.0222, 0.0222),
      //dimensions:{ width: 1, height: 1, depth: 1 },
    },
  ], []);
  return (
    <>
      <PerspectiveCamera makeDefault position={initialCameraPosition.current} fov={75} />
      <OrbitControls
          ref={orbitControlsRef}
          enableDamping
          dampingFactor={0.25}
          maxDistance={2}
          minDistance={0.5}
          target={initialTargetPosition.current}
          enablePan={true}
          onChange={() => {
            if(orbitControlsRef.current){
            initialCameraPosition.current.copy(camera.position);
            initialTargetPosition.current.copy(orbitControlsRef.current.target);
          }
          }}
        />
      <ambientLight intensity={lightingOptions.ambientLightIntensity} color={lightingOptions.ambientLightColor} />

      <pointLight 
        position={[3, 3.2, -3.5]} 
        intensity={50} 
        color="#FFFFFF" 
        castShadow
        shadow-mapSize={[1024, 1024]}
      />

      <pointLight 
        position={[-0.2, 1.5, -7]} 
        intensity={5} 
        color="#FFFFFF" 
        castShadow
        shadow-mapSize={[1024, 1024]}
      />

    <color attach="background" args={["#333333"]} />

  <group ref={groupRef}>
    {staticObjects.map((obj, index) => (
      <CustomObject3D
        key={`static-object-${index}`}
        filePath={obj.filePath || ''}
        fileType={(obj.fileType as "fbx" | "stl" | "3ds" | "obj") || "obj"}
        initialPosition={obj.initialPosition || new THREE.Vector3()}
        initialRotation={obj.initialRotation || new THREE.Euler()}
        initialScale={obj.initialScale || new THREE.Vector3(1, 1, 1)}
      />
    ))}

      {components.map((component) => (
        component.obj && (
          <group key={component.id}>
            <primitive 
              object={component.obj} 
              position={component.position}
              rotation={component.rotation}
              onClick={(e: { stopPropagation: () => void; }) => {
                e.stopPropagation();
                handleSelectComponent(component.id);
              }}
            />
            {selectedComponent === component.id && (
              <TransformControls
                ref={transformControlsRef}
                object={component.obj}
                mode={transformMode}
                space="local"
                onObjectChange={() => {
                  if (component.obj) {
                    handleTransformChange(component.id);
                    onUpdateComponent(component.id, component.obj.position, new THREE.Euler().setFromQuaternion(component.obj.quaternion));
                  }
                }}
                onMouseDown={() => {
                  if (orbitControlsRef.current) {
                    orbitControlsRef.current.enabled = false;
                  }
                }}
                onMouseUp={() => {
                  if (orbitControlsRef.current) {
                    orbitControlsRef.current.enabled = true;
                  }
                }}
              />
            )}
          </group>
        )
      ))}
    </group>

        {/* <Perf 
          position="top-right"
          antialias={false}
          deepAnalyze={false}  // Changed to false
          matrixUpdate={false}  // Changed to false
          overClock={false}  // Changed to false
          passes={false}  // Changed to false
          showGraph={false}  // Changed to false
          chart={{
            cpu: true,
            fps: true,
            memory: true
          }}
          minimal={false}  // Added minimal mode
          logsPerSecond={1}  // Reduced update frequency
        /> */}
    </>
  );
};

export default LowEndScene;