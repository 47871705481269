import { useState, useCallback } from 'react';
import * as THREE from 'three';
import { PCComponent, LightingOptions, TableOptions, CaseOptions, MaterialType, SceneType, PC, SocketInfo, PartMeasurements, Motherboard, ComponentPosition } from '../types/types';

const DEFAULT_PC: PC = {
  id: 'pc',
  category: 'PC',
  modelName: 'Custom PC',
  position: new THREE.Vector3(0, 0, 0),
  rotation: new THREE.Euler(0, 0, 0),
  obj: null,

  present: true,
  dimensions: new THREE.Vector3(0, 0, 0),
  case: {
    id: '',
    category: 'case',
    modelName: '',
    position: new THREE.Vector3(0, 0, 0),
    rotation: new THREE.Euler(0, 0, 0),
    obj: null,
    present: false,
    maxGpuLength: 0,
    maxCoolerHeight: 0,
    dimensions: new THREE.Vector3(0, 0, 0),
    motherboard: {
      id: '',
      category: 'motherboard',
      modelName: '',
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Euler(0, 0, 0),
      obj: null,
      present: false,
      dimensions: new THREE.Vector3(0, 0, 0),
      socket: '',
      componentPositions: {
        cpu: { x: 0, y: 0 },
        gpu: { x: 0, y: 0 },
        memory: { x: 0, y: 0 }
      },
      cpu: {
        id: '',
        category: 'cpu',
        modelName: '',
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Euler(0, 0, 0),
        obj: null,
        present: false,
        dimensions: new THREE.Vector3(0, 0, 0),
      },
      cooler: {
        id: '',
        category: 'cooler',
        modelName: '',
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Euler(0, 0, 0),
        obj: null,
        present: false,
        dimensions: new THREE.Vector3(0, 0, 0),
      },
      gpu: {
        id: '',
        category: 'gpu',
        modelName: '',
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Euler(0, 0, 0),
        obj: null,
        present: false,
        dimensions: new THREE.Vector3(0, 0, 0),
      },
      m2: {
        id: '',
        category: 'm2',
        modelName: '',
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Euler(0, 0, 0),
        obj: null,
        present: false,
        dimensions: new THREE.Vector3(0, 0, 0),
      },
      storage: {
        id: '',
        category: 'storage',
        modelName: '',
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Euler(0, 0, 0),
        obj: null,
        present: false,
        dimensions: new THREE.Vector3(0, 0, 0),
      },
      memory: {
        id: '',
        category: 'memory',
        modelName: '',
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Euler(0, 0, 0),
        obj: null,
        present: false,
        dimensions: new THREE.Vector3(0, 0, 0),
      },
    },
    psu: {
      id: '',
      category: 'psu',
      modelName: '',
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Euler(0, 0, 0),
      obj: null,
      present: false,
      dimensions: new THREE.Vector3(0, 0, 0),
    },
  },
};

const SOCKET_INFO: SocketInfo = {
  'AM4': {
    motherboards: ['B550', 'X570', 'A520', 'B450', 'X470'],
    cpuPattern: /ryzen.*r[3579].*[35]\d{3}X?/i
  },
  'AM5': {
    motherboards: ['B650', 'X670', 'A620'],
    cpuPattern: /ryzen.*r[3579].*[79]\d{3}X?/i
  },
  'LGA1700': {
    motherboards: ['Z690', 'Z790', 'B660', 'H670', 'H610'],
    cpuPattern: /Core.*i[3579]-1[23]\d{3}K?F?/i
  },
  'LGA1200': {
    motherboards: ['Z490', 'Z590', 'B560', 'H510', 'H470', 'B460'],
    cpuPattern: /Core.*i[3579]-1[01]\d{3}K?F?/i
  },
  'LGA1151': {
    motherboards: ['Z170', 'Z270', 'Z370', 'Z390'],
    cpuPattern: /Core.*i[3579]-6\d{3}K?F?/i
  },
  'LGA1150': {
    motherboards: ['Z87', 'Z97', 'H87', 'H97'],
    cpuPattern: /Core.*i[3579]-4\d{3}K?F?/i
  },
  'LGA2011-3': {
    motherboards: ['X99', 'X299'],
    cpuPattern: /Core.*i[79]-[6789]\d{3}X?/i
  },
  'LGA2066': {
    motherboards: ['X299'],
    cpuPattern: /Core.*i[79]-[1789]\d{3}X?/i
  }
};

export const usePCBuilderState = () => {
  const [components, setComponents] = useState<PCComponent[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<string | null>(null);
  const [sceneType, setSceneType] = useState<SceneType>('constructor3d');
  const [lightingOptions, setLightingOptions] = useState<LightingOptions>({
    ambientLightIntensity: 1,
    ambientLightColor: '#ffffff',
    directionalLightIntensity: 2,
    directionalLightColor: '#ffffff',
    directionalLightPosition: new THREE.Vector3(2, 2.3, -20),
  });
  const [backgroundColor, setBackgroundColor] = useState('#111111');
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    topColor: '#5c3b24',
    legColor: '#3a1f0f',
    width: 4,
    depth: 2,
    height: 1,
    legThickness: 0.1,
  });
  const [caseOptions, setCaseOptions] = useState<CaseOptions>({
    psuPosition: 'bottom',
    motherboardOrientation: 'vertical',
  });
  const [materialType, setMaterialType] = useState<MaterialType>('coat');
  const [lightPresets, setLightPresets] = useState<LightingOptions[]>([]);
  const [pc, setPc] = useState<PC>(DEFAULT_PC);
  const [partMeasurements, setPartMeasurements] = useState<PartMeasurements>({});

  const updateLightingOption = useCallback((key: keyof LightingOptions, value: any) => {
    setLightingOptions(prev => ({ ...prev, [key]: value }));
  }, []);

  const updateTableOption = useCallback((key: keyof TableOptions, value: any) => {
    setTableOptions(prev => ({ ...prev, [key]: value }));
  }, []);

  const updateCaseOption = useCallback((key: keyof CaseOptions, value: any) => {
    setCaseOptions(prev => ({ ...prev, [key]: value }));
  }, []);

  const saveLightPreset = useCallback(() => {
    setLightPresets(prev => [...prev, { ...lightingOptions }]);
  }, [lightingOptions]);

  const loadLightPreset = useCallback((index: number) => {
    if (lightPresets[index]) {
      setLightingOptions(lightPresets[index]);
    }
  }, [lightPresets]);

  const fetchMotherboardLayout = async (motherboardModel: string) => {
    try {
      const modelName = motherboardModel;
      const response = await fetch(`/mb_part_positions/${modelName}.json`);
      const data = await response.json();
      
      const componentPositions: { [key: string]: ComponentPosition } = {};
      
      data.detections.forEach((detection: any) => {
        const { class: detectionClass, normalized_coordinates: { x1, y1 } } = detection;
        
        switch (detectionClass) {
          case 'CPU_SOCKET':
            if (!componentPositions.cpu || x1 < componentPositions.cpu.x || y1 < componentPositions.cpu.y) {
              componentPositions.cpu = { x: x1, y: y1 };
            }
            break;
          case 'PCIE_SLOT':
            if (!componentPositions.gpu || y1 < componentPositions.gpu.y) {
              componentPositions.gpu = { x: x1, y: y1 };
            }
            break;
          case 'RAM_SLOT':
            if (!componentPositions.memory || x1 < componentPositions.memory.x) {
              componentPositions.memory = { x: x1, y: y1 };
            }
            break;
        }
      });
  
      //console.log("Fetched component positions:", componentPositions);
      return componentPositions;
    } catch (error) {
      console.error('Error loading motherboard layout:', error);
      return null;
    }
  };
  
  const addPart = async (partId: string, modelName: string) => {
    //console.log(`Adding part: ${partId}, ${modelName}`);
    setPc((prevPc) => {
      let updatedPc = { ...prevPc };
      const part = getPart(partId, updatedPc);

      //console.log('Part retrieved:', part);

      if (part) {
        part.modelName = modelName;
        part.present = true;
        //console.log('Part after update:', part);

        if (partId === 'motherboard') {
          updatedPc.case.motherboard.socket = getSocketFromMotherboard(modelName);
          fetchMotherboardLayout(modelName).then(componentPositions => {
            if (componentPositions) {
              setPc(currentPc => {
                const updatedPcWithPositions = {
                  ...currentPc,
                  case: {
                    ...currentPc.case,
                    motherboard: {
                      ...currentPc.case.motherboard,
                      componentPositions
                    } as Motherboard
                  }
                };
                return updateComponentPositions(updatedPcWithPositions);
              });
            }
          });
        } else if (partId === 'gpu' || partId === 'cooler' || partId === 'case') {
          part.dimensions = partMeasurements[partId];
          if (partId === 'case') {
            updatedPc.case.maxGpuLength = part.dimensions?.x || 0;
            updatedPc.case.maxCoolerHeight = part.dimensions?.y || 0;
          }
        }
      }

      return updatedPc;
    });
  };
 // this doesnt work because this code runs before the motherboard gets its dimensions
  const updateComponentPositions = (updatedPc: PC) => {
    if (updatedPc.case.motherboard.present && updatedPc.case.motherboard.dimensions && updatedPc.case.motherboard.componentPositions) {
      const motherboardPosition = updatedPc.case.motherboard.position;
      const motherboardDimensions = updatedPc.case.motherboard.dimensions;
      const componentPositions = updatedPc.case.motherboard.componentPositions;
      (['cpu', 'gpu', 'memory'] as const).forEach(partId => {
        if (componentPositions[partId]) {
          const newPosition = new THREE.Vector3(
            motherboardPosition.x + (motherboardDimensions.x * componentPositions[partId].x),
            motherboardPosition.y + (motherboardDimensions.y * componentPositions[partId].y),
            motherboardPosition.z + 0.01
          );
          updatedPc.case.motherboard[partId].position = newPosition;
        }
      });
    }
    return updatedPc;
  };

  const removePart = (partId: string) => {
    setPc((prevPc) => {
      const updatedPc = { ...prevPc };
      const part = getPart(partId, updatedPc);
  
      if (part) {
        if (part.obj && part.obj.parent) {
          part.obj.parent.remove(part.obj);
        }
  
        const createEmptyComponent = (category: string) => ({
          category,
          dimensions: null,
          id: '',
          modelName: '',
          obj: null,
          parentId: null,
          position: null,
          present: false,
          rotation: null,
        });
  
        switch (part.category) {
          case 'case':
            Object.assign(part, {
              ...createEmptyComponent('case'),
              maxCoolerHeight: null,
              maxGpuLength: null,
            });
            break;
          case 'cpu':
            Object.assign(part, createEmptyComponent('cpu'));
            break;
          case 'cooler':
            Object.assign(part, createEmptyComponent('cooler'));
            break;
          case 'gpu':
            Object.assign(part, createEmptyComponent('gpu'));
            break;
          case 'motherboard':
            Object.assign(part, {
              ...createEmptyComponent('motherboard'),
              socket: '',
            });
            break;
          case 'psu':
            Object.assign(part, createEmptyComponent('psu'));
            break;
          case 'memory':
            Object.assign(part, createEmptyComponent('memory'));
            break;
          case 'storage':
            Object.assign(part, createEmptyComponent('storage'));
            break;
          case 'm2':
            Object.assign(part, createEmptyComponent('m2'));
            break;
          default:
            console.warn(`Unknown component category: ${part.category}`);
        }
      }
  
      return updatedPc;
    });
  };

  const checkCompatibility = (): string[] => {
    const issues: string[] = [];

    if (pc.case.motherboard.present && pc.case.motherboard.cpu.present) {
      if (!isCPUCompatible(pc.case.motherboard.cpu.modelName, pc.case.motherboard.socket)) {
        issues.push(`CPU (${pc.case.motherboard.cpu.modelName}) is not compatible with motherboard socket (${pc.case.motherboard.socket})`);
      }
    }

    if (pc.case.present && pc.case.motherboard.gpu.present) {
      const gpuLength = pc.case.motherboard.gpu.dimensions?.x || 0;
      if (gpuLength / 2 > pc.case.maxGpuLength / 2) {
        issues.push(`GPU length (${(gpuLength / 2 * 1000).toFixed(0)} mm) exceeds case maximum GPU length (${(pc.case.maxGpuLength / 2 * 1000).toFixed(0)} mm)`);
      }
    }

    if (pc.case.present && pc.case.motherboard.cooler.present) {
      const coolerHeight = pc.case.motherboard.cooler.dimensions?.y || 0;
      if (coolerHeight > pc.case.maxCoolerHeight) {
        issues.push(`Cooler height (${(coolerHeight / 2 * 1000).toFixed(0)} mm) exceeds case maximum cooler height (${(pc.case.maxCoolerHeight / 2 * 1000).toFixed(0)} mm)`);
      }
    }

    return issues;
  };

  const getSocketFromMotherboard = (motherboardName: string): string => {
    for (const [socket, info] of Object.entries(SOCKET_INFO)) {
      if (info.motherboards.some(pattern => 
        typeof pattern === 'string' 
          ? motherboardName.toLowerCase().includes(pattern.toLowerCase())
          : pattern.test(motherboardName)
      )) {
        return socket;
      }
    }
    return 'Unknown';
  };
  
  const isCPUCompatible = (cpuName: string, socket: string): boolean => {
    const socketData = SOCKET_INFO[socket as keyof typeof SOCKET_INFO];
    return socketData ? socketData.cpuPattern.test(cpuName) : false;
  };

  const getPart = (partId: string, currentPc: PC): PCComponent | undefined => {
    const partMap: { [key: string]: PCComponent } = {
      case: currentPc.case,
      motherboard: currentPc.case.motherboard,
      cpu: currentPc.case.motherboard.cpu,
      cooler: currentPc.case.motherboard.cooler,
      gpu: currentPc.case.motherboard.gpu,
      m2: currentPc.case.motherboard.m2,
      storage: currentPc.case.motherboard.storage,
      memory: currentPc.case.motherboard.memory,
      psu: currentPc.case.psu,
    };

    const part = partMap[partId];
    if (!part) {
      console.error(`Part not found: ${partId}`);
    }
    return part;
  };

  return {
    components, setComponents,
    isLoading, setIsLoading,
    selectedAsset, setSelectedAsset,
    sceneType, setSceneType,
    lightingOptions, updateLightingOption,
    backgroundColor, setBackgroundColor,
    tableOptions, updateTableOption,
    caseOptions, updateCaseOption,
    materialType, setMaterialType,
    lightPresets, saveLightPreset, loadLightPreset,
    pc, 
    setPc,
    partMeasurements, setPartMeasurements,
    addPart,
    removePart,
    checkCompatibility,
  };
};