import { useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import { Group } from 'three';
import React from 'react';

interface TooltipProps {
  showTooltip: boolean;
  message: string;
  fontSize?: number;
  color?: string;
  offset?: [number, number, number]; // Optional offset in the format [x, y, z]
}

export const Tooltip: React.FC<TooltipProps> = ({
  showTooltip,
  message,
  fontSize = 0.05,
  color = 'white',
  offset = [0, 0, -1], // Slight offset downward and forward
}) => {
  const { camera } = useThree();
  const tooltipGroup = useRef<Group>(null);

  // Attach the tooltip group directly to the camera
  if (tooltipGroup.current) {
    camera.add(tooltipGroup.current);
  }

  if (!showTooltip) {
    return null; // Return null if the tooltip is not visible
  }

  return (
    <group ref={tooltipGroup} position={offset}>
      <Text
        fontSize={fontSize}
        color={color}
        anchorX="center"
        anchorY="middle"
      >
        {message}
      </Text>
    </group>
  );
};
