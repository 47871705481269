import React, { useState } from 'react';
import styles from './PCBuilderControls.module.css';
import LightingSettings from './sections/LightingSettings';
import MaterialSettings from './sections/MaterialSettings';
import LightPresets from './sections/LightPresets';
import PCConfiguration from './sections/PCConfiguration';
import { PCBuilderControlsProps } from '../../types/types';

enum ControlSection {
  PC = 'PC',
  Lighting = 'Lighting',
  Material = 'Material',
  Presets = 'Presets'
}

const PCBuilderControls: React.FC<PCBuilderControlsProps> = (props) => {
  const [activeSection, setActiveSection] = useState<ControlSection>(ControlSection.PC);

  const renderSection = () => {
    switch (activeSection) {
      case ControlSection.PC:
        return (
          <PCConfiguration
            pc={props.pc}
            setPc={props.setPc}
            components={props.components}
            setComponents={props.setComponents}
            addPart={props.addPart}
            removePart={props.removePart}
            checkCompatibility={props.checkCompatibility}
            isLoading={props.isLoading}
            setIsLoading={props.setIsLoading}
            setSelectedAsset={props.setSelectedAsset}
          />
        );
      case ControlSection.Lighting:
        return (
          <LightingSettings
            lightingOptions={props.lightingOptions}
            updateLightingOption={props.updateLightingOption}
          />
        );
      case ControlSection.Material:
        return (
          <MaterialSettings
            materialType={props.materialType}
            setMaterialType={props.setMaterialType}
          />
        );
      case ControlSection.Presets:
        return (
          <LightPresets
            saveLightPreset={props.saveLightPreset}
            loadLightPreset={props.loadLightPreset}
            lightPresets={props.lightPresets}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.navigationButtons}>
          {Object.values(ControlSection).map((section) => (
            <button
              key={section}
              className={`${styles.navButton} ${activeSection === section ? styles.activeNavButton : ''}`}
              onClick={() => setActiveSection(section)}
            >
              {section}
            </button>
          ))}
        </div>
        
        <div className={styles.sectionContent}>
          {renderSection()}
        </div>
      </div>
    </div>
  );
};

export default PCBuilderControls;