import pako from 'pako';

interface AssetInfo {
  chunks: string[];
  totalSize: number;
}

interface AssetManifest {
  [key: string]: AssetInfo;
}

class AssetLoader {
  private manifest: AssetManifest | null = null;

  async loadManifest() {
    if (this.manifest) return;
    try {
      const response = await fetch('/assets/chunks/manifest.json');
      this.manifest = await response.json();
    } catch (error) {
      console.error('Error loading manifest:', error);
    }
  }

  private availabilityCache: { [key: string]: boolean } = {};

  async checkAssetsAvailability(assetIds: string[]): Promise<Record<string, boolean>> {
    if (!this.manifest) {
      await this.loadManifest();
    }

    const results: Record<string, boolean> = {};
    const uncachedAssets = assetIds.filter(id => !this.availabilityCache.hasOwnProperty(id));

    if (uncachedAssets.length > 0) {
      const checkPromises = uncachedAssets.map(async (assetId) => {
        if (!this.manifest || !(assetId in this.manifest)) {
          this.availabilityCache[assetId] = false;
          return;
        }

        const assetInfo = this.manifest[assetId];

        if (!assetInfo.chunks || assetInfo.chunks.length === 0) {
          this.availabilityCache[assetId] = false;
          return;
        }

        const chunkPromises = assetInfo.chunks.map(async (chunk) => {
          const chunkUrl = `/assets/chunks/${chunk}`;
          try {
            const response = await fetch(chunkUrl, {
              method: 'GET',
              headers: { 'Range': 'bytes=0-1' }
            });
            const text = await response.text();
            return !text.startsWith('<!');
          } catch {
            return false;
          }
        });

        const chunkResults = await Promise.all(chunkPromises);
        this.availabilityCache[assetId] = chunkResults.every(result => result);
      });

      await Promise.all(checkPromises);
    }

    assetIds.forEach(id => {
      results[id] = this.availabilityCache[id] || false;
    });

    return results;
  }

  getCategories(): string[] {
    if (!this.manifest) return [];
    const categories = new Set(Object.keys(this.manifest).map(key => key.split('/')[0]));
    return Array.from(categories);
  }

  getModels(category: string): string[] {
    if (!this.manifest) return [];
    return Object.keys(this.manifest)
      .filter(key => key.startsWith(`${category}/`))
      .map(key => key.split('/')[1]);
  }

  async loadAsset(assetId: string): Promise<ArrayBuffer> {
    if (!this.manifest) {
      await this.loadManifest();
    }
    if (!this.manifest) throw new Error('Manifest not loaded');

    const assetInfo = this.manifest[assetId];
    if (!assetInfo) {
      throw new Error(`Asset not found: ${assetId}`);
    }

    const chunks = await this.loadChunks(assetInfo.chunks);
    const compressedData = this.assembleChunks(chunks);
    return pako.inflate(new Uint8Array(compressedData)).buffer;
  }

  private async loadChunks(chunkIds: string[]): Promise<ArrayBuffer[]> {
    return Promise.all(chunkIds.map(this.loadChunk));
  }

  private async loadChunk(chunkId: string): Promise<ArrayBuffer> {
    const response = await fetch(`/assets/chunks/${chunkId}`);
    return response.arrayBuffer();
  }

  private assembleChunks(chunks: ArrayBuffer[]): ArrayBuffer {
    const totalLength = chunks.reduce((sum, chunk) => sum + chunk.byteLength, 0);
    const result = new Uint8Array(totalLength);
    let offset = 0;
    for (const chunk of chunks) {
      result.set(new Uint8Array(chunk), offset);
      offset += chunk.byteLength;
    }
    return result.buffer;
  }
}

export const assetLoader = new AssetLoader();