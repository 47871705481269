import React, { useState, useEffect } from 'react';
import styles from '../PCBuilderControls.module.css';
import * as THREE from 'three';
import { PC, PCComponent } from '../../../types/types';
import { assetLoader } from '../../../services/assetLoader';
import { handleFetchChunks } from '../../../services/utils';
import PartSelectionModal from './PartSelectionModal';

const PART_CATEGORIES: { [key: string]: string[] } = {
  case: ['case'],
  motherboard: ['motherboard'],
  cpu: ['cpu'],
  cooler: ['cooler'],
  gpu: ['gpu'],
  m2: ['m2'],
  storage: ['storage', 'ssd'],
  memory: ['memory'],
  psu: ['psu']
};

interface PCConfigurationProps {
  pc: PC;
  setPc: React.Dispatch<React.SetStateAction<PC>>;
  components: PCComponent[];
  setComponents: React.Dispatch<React.SetStateAction<PCComponent[]>>;
  addPart: (partId: string, modelName: string) => void;
  removePart: (id: string, partId: string) => void;
  checkCompatibility: () => string[];
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAsset: React.Dispatch<React.SetStateAction<string | null>>;
}

const PCConfiguration: React.FC<PCConfigurationProps> = ({
  pc,
  setPc,
  components,
  setComponents,
  addPart,
  removePart,
  checkCompatibility,
  isLoading,
  setIsLoading,
  setSelectedAsset,
}) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<{ [key: string]: string }>({});
  const [models, setModels] = useState<{ [key: string]: PCComponent[] }>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPartId, setCurrentPartId] = useState<string | null>(null);

  useEffect(() => {
    const initializeCategories = async () => {
      await assetLoader.loadManifest();
      const availableCategories = assetLoader.getCategories();
      setCategories(availableCategories);

      const initialCategories: { [key: string]: string } = {};
      Object.entries(PART_CATEGORIES).forEach(([partId, partCategories]) => {
        const availablePartCategories = partCategories.filter(cat => availableCategories.includes(cat));
        if (availablePartCategories.length > 0) {
          initialCategories[partId] = availablePartCategories[0];
        }
      });

      setSelectedCategories(initialCategories);
    };

    initializeCategories();
  }, []);

  useEffect(() => {
    const loadModels = async () => {
      const newModels: { [key: string]: PCComponent[] } = {};
      for (const [partId, category] of Object.entries(selectedCategories)) {
        if (category) {
          const modelNames = await assetLoader.getModels(category);
          newModels[partId] = modelNames.map(modelName => ({
            id: `${category}_${modelName}`,
            modelName,
            category,
            present: false,
            position: new THREE.Vector3(),
            rotation: new THREE.Euler(),
            obj: null,
            dimensions: new THREE.Vector3(),
            parentId: null
          }));
        }
      }
      setModels(newModels);
    };

    if (Object.keys(selectedCategories).length > 0) {
      loadModels();
    }
  }, [selectedCategories]);

  const getPart = (partId: string, currentPc: PC): PCComponent | undefined => {
    const partMap: { [key: string]: PCComponent } = {
      case: currentPc.case,
      motherboard: currentPc.case.motherboard,
      cpu: currentPc.case.motherboard.cpu,
      cooler: currentPc.case.motherboard.cooler,
      gpu: currentPc.case.motherboard.gpu,
      m2: currentPc.case.motherboard.m2,
      storage: currentPc.case.motherboard.storage,
      memory: currentPc.case.motherboard.memory,
      psu: currentPc.case.psu,
    };

    return partMap[partId];
  };

  const handlePartSelect = async (partId: string, component: PCComponent) => {
    setIsLoading(true);
    const assetId = `${component.category}/${component.modelName}`;
    setSelectedAsset(assetId);

    try {
      await handleFetchChunks(assetId, setComponents, setIsLoading, setSelectedAsset, pc, setPc, 2);
      addPart(partId, component.modelName);
    } catch (error) {
      console.error("Error in handleFetchChunks:", error);
    } finally {
      setIsLoading(false);
      setModalOpen(false);
    }
  };

  const openPartSelection = (partId: string) => {
    setCurrentPartId(partId);
    setModalOpen(true);
  };

  const renderPartInfo = (partId: string) => {
    const part = getPart(partId, pc);

    return (
      <div className={styles.controlGroup}>
        <label className={styles.label}>{partId.charAt(0).toUpperCase() + partId.slice(1)}:</label>
        {part?.present ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`/images/${part.category}/${part.modelName}.png`}
              onError={(e) => {
                if (!e.currentTarget.src.endsWith('.jpg')) {
                  e.currentTarget.src = `/images/${part.category}/${part.modelName}.jpg`;
                } else {
                  e.currentTarget.style.display = 'none';
                }
              }}
              alt={part.modelName}
              className={styles.partImage}
              style={{ maxWidth: '150px', maxHeight: '150px', marginRight: '10px' }}
            />
            <div>
              <span className={styles.value}>{part.modelName}</span>
              <button className={styles.button} onClick={() => removePart(partId, part.id)}>Remove</button>
            </div>
          </div>
        ) : (
          <button className={styles.button} onClick={() => openPartSelection(partId)} disabled={isLoading}>
            Select {partId}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={styles.section}>
      <h3 className={styles.sectionTitle}>PC Configuration</h3>
      {['case', 'motherboard', 'cpu', 'cooler', 'gpu', 'm2', 'storage', 'memory', 'psu'].map(partId => (
        <React.Fragment key={partId}>
          {renderPartInfo(partId)}
        </React.Fragment>
      ))}
      <div>
        <h4>Compatibility Issues:</h4>
        <ul className={styles.compatibilityList}>
          {checkCompatibility().map((issue, index) => (
            <li key={index}>{issue}</li>
          ))}
        </ul>
      </div>
      {modalOpen && currentPartId && (
        <PartSelectionModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          parts={models[currentPartId] || []}
          onSelect={(component) => handlePartSelect(currentPartId, component)}
          partType={currentPartId}
        />
      )}
    </div>
  );
};

export default PCConfiguration;