import { useState, useEffect } from 'react';

const STORAGE_KEYS = {
  ITEMS_PER_PAGE: 'pc-builder:products:itemsPerPage',
  ITEMS_PER_ROW: 'pc-builder:products:itemsPerRow',
  USE_INFINITE_SCROLL: 'pc-builder:products:useInfiniteScroll',
  SORT_BY: 'pc-builder:products:sortBy',
  SELECTED_CATEGORY: 'pc-builder:products:selectedCategory'
} as const;

type SortBy = 'price-asc' | 'price-desc' | 'rating';

export const useProductPreferences = () => {
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    const saved = localStorage.getItem(STORAGE_KEYS.ITEMS_PER_PAGE);
    return saved ? parseInt(saved) : 24;
  });

  const [itemsPerRow, setItemsPerRow] = useState(() => {
    const saved = localStorage.getItem(STORAGE_KEYS.ITEMS_PER_ROW);
    return saved ? parseInt(saved) : 4;
  });

  const [useInfiniteScroll, setUseInfiniteScroll] = useState(() => {
    const saved = localStorage.getItem(STORAGE_KEYS.USE_INFINITE_SCROLL);
    return saved === 'true';
  });

  const [sortBy, setSortBy] = useState<SortBy>(() => {
    const saved = localStorage.getItem(STORAGE_KEYS.SORT_BY);
    return (saved as SortBy) || 'rating';
  });

  const [selectedCategory, setSelectedCategory] = useState(() => {
    const saved = localStorage.getItem(STORAGE_KEYS.SELECTED_CATEGORY);
    return saved || 'all';
  });

  // Save preferences whenever they change
  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.ITEMS_PER_PAGE, itemsPerPage.toString());
  }, [itemsPerPage]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.ITEMS_PER_ROW, itemsPerRow.toString());
  }, [itemsPerRow]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.USE_INFINITE_SCROLL, useInfiniteScroll.toString());
  }, [useInfiniteScroll]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.SORT_BY, sortBy);
  }, [sortBy]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.SELECTED_CATEGORY, selectedCategory);
  }, [selectedCategory]);

  return {
    itemsPerPage,
    setItemsPerPage,
    itemsPerRow,
    setItemsPerRow,
    useInfiniteScroll,
    setUseInfiniteScroll,
    sortBy,
    setSortBy,
    selectedCategory,
    setSelectedCategory
  };
};
