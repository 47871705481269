import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Layout/Header';
import HomePage from './pages';
import Products from './pages/products';
import CompletedBuilds from './pages/completed-builds';

const ProductPage = React.lazy(() => import('./pages/product/[category]/[model]'));

const App: React.FC = () => {
  return (
    <Router>
      <div className="h-screen flex flex-col bg-black-900 text-white">      
        <Header/>
        <Suspense fallback={
          <div className="container mx-auto px-4 py-8">
            <div className="animate-pulse">
              <div className="h-8 bg-gray-700 rounded w-1/4 mb-4"></div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="aspect-square bg-gray-700 rounded-lg"></div>
                <div className="space-y-4">
                  <div className="h-6 bg-gray-700 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-700 rounded w-1/2"></div>
                  <div className="h-4 bg-gray-700 rounded w-2/3"></div>
                </div>
              </div>
            </div>
          </div>
        }>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/products" element={<Products />} />
            <Route path="/product/:category/:model" element={<ProductPage />} />
            <Route path="/completed-builds" element={<CompletedBuilds />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
