import React, { useRef, useState, useEffect, useMemo } from 'react';
import * as THREE from 'three';
import { useLoader, useThree } from '@react-three/fiber';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { TDSLoader } from 'three/examples/jsm/loaders/TDSLoader';
import { Text } from '@react-three/drei';

interface CustomObject3DProps {
  filePath: string;
  fileType: 'fbx' | 'stl' | 'obj' | '3ds';
  texturePath?: string;
  initialPosition: THREE.Vector3;
  initialRotation: THREE.Euler;
  initialScale: THREE.Vector3;
}

const CustomObject3D: React.FC<CustomObject3DProps> = React.memo(({
  filePath,
  fileType,
  texturePath,
  initialPosition,
  initialRotation,
  initialScale,
}) => {
  const { scene } = useThree();
  const objectRef = useRef<THREE.Object3D | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [dimensions, setDimensions] = useState<THREE.Vector3 | null>(null);

  const loader = useMemo(() => {
    switch (fileType) {
      case 'fbx': return new FBXLoader();
      case 'stl': return new STLLoader();
      case 'obj': return new OBJLoader();
      case '3ds': return new TDSLoader();
      default: throw new Error('Unsupported file type');
    }
  }, [fileType]);

  useEffect(() => {
    let isMounted = true;
    const loadObject = async () => {
      if (!filePath) {
        console.error('Error: filePath is undefined or null: ' + filePath);
        if (isMounted) {
          setError('Failed to load 3D object: File path is missing: ' + filePath);
        }
        return;
      }
      try {
        const object = await loader.loadAsync(filePath);

        if (!isMounted) return;

        let mesh: THREE.Mesh | THREE.Object3D;
        if (object instanceof THREE.Group || object instanceof THREE.Mesh) {
          mesh = object;
        } else if (object instanceof THREE.BufferGeometry) {
          mesh = new THREE.Mesh(object, new THREE.MeshStandardMaterial());
        } else {
          throw new Error('Unsupported object type');
        }

        if (texturePath) {
          const texture = await new THREE.TextureLoader().loadAsync(texturePath);
          if (!isMounted) return;
          texture.colorSpace = THREE.SRGBColorSpace;
          const material = new THREE.MeshStandardMaterial({
            map: texture,
            color: 0xFFFFFF,
          });
          if (mesh instanceof THREE.Mesh) {
            mesh.material = material;
          } else if (mesh instanceof THREE.Group) {
            mesh.traverse((child) => {
              if (child instanceof THREE.Mesh) {
                child.material = material;
              }
            });
          }
        } else {
          const applyDefaultMaterial = (childMesh: THREE.Mesh) => {
            if (!childMesh.material) {
              childMesh.material = new THREE.MeshStandardMaterial({ color: 0xFFFFFF });
            } else if (Array.isArray(childMesh.material)) {
              childMesh.material = childMesh.material.map(mat => 
                mat instanceof THREE.Material ? mat : new THREE.MeshStandardMaterial({ color: 0xFFFFFF })
              );
            } else if (!(childMesh.material instanceof THREE.Material)) {
              childMesh.material = new THREE.MeshStandardMaterial({ color: 0xFFFFFF });
            }
          };

          if (mesh instanceof THREE.Mesh) {
            applyDefaultMaterial(mesh);
          } else if (mesh instanceof THREE.Group) {
            mesh.traverse((child) => {
              if (child instanceof THREE.Mesh) {
                applyDefaultMaterial(child);
              }
            });
          }
        }

        mesh.position.copy(initialPosition);
        mesh.rotation.copy(initialRotation);
        mesh.scale.copy(initialScale);
        
        objectRef.current = mesh;
        scene.add(mesh);

        const box = new THREE.Box3().setFromObject(mesh);
        const size = box.getSize(new THREE.Vector3());
        setDimensions(size);

      } catch (err: unknown) {
        console.error('Error loading 3D object:', err);
        if (isMounted) {
          setError(`Failed to load 3D object: ${err instanceof Error ? err.message : String(err)}`);
        }
      }
    };

    loadObject();

    return () => {
      isMounted = false;
      if (objectRef.current) {
        scene.remove(objectRef.current);
        objectRef.current = null;
      }
    };
  }, [filePath, fileType, texturePath, initialPosition, initialRotation, initialScale, scene]);

  if (error) {
    return (
      <group position={initialPosition}>
        <mesh>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color="red" wireframe />
        </mesh>
        <Text position={[0, 0.6, 0]} fontSize={0.1} color="red" anchorX="center" anchorY="bottom">
          {error}
        </Text>
      </group>
    );
  }

  return null;
});

export default CustomObject3D;