interface PartPricing {
    price: string;
    rating: string;
    ratingCount: number;
    totalRating: number;
}

class PriceService {
    private static STORAGE_KEY = 'pc_builder_prices';

    private generatePrice(): string {
        return (Math.random() * 490 + 10).toFixed(2).slice(0, -2) + '99';
    }

    private generateRating(): Omit<PartPricing, 'price'> {
        return {
            rating: "0.0",
            ratingCount: 0,
            totalRating: 0
        };
    }

    addRating(partId: string, rating: number): void {
        const stored = localStorage.getItem(PriceService.STORAGE_KEY);
        const prices = stored ? JSON.parse(stored) : {};
        
        if (!prices[partId]) {
            prices[partId] = {
                price: this.generatePrice(),
                ...this.generateRating()
            };
        }

        prices[partId].totalRating = (prices[partId].totalRating || 0) + rating;
        prices[partId].ratingCount = (prices[partId].ratingCount || 0) + 1;
        prices[partId].rating = (prices[partId].totalRating / prices[partId].ratingCount).toFixed(1);
        
        localStorage.setItem(PriceService.STORAGE_KEY, JSON.stringify(prices));
    }

    getPricing(partId: string): PartPricing {
        const stored = localStorage.getItem(PriceService.STORAGE_KEY);
        const prices = stored ? JSON.parse(stored) : {};

        if (!prices[partId]) {
            prices[partId] = {
                price: this.generatePrice(),
                ...this.generateRating()
            };
            localStorage.setItem(PriceService.STORAGE_KEY, JSON.stringify(prices));
        }

        return prices[partId];
    }

    clearPrices(): void {
        localStorage.removeItem(PriceService.STORAGE_KEY);
    }
}

export const priceService = new PriceService();
