import React, { useState } from 'react';
import styles from './StartOverlay.module.css';

interface StartOverlayProps {
  onStart: () => void;
}

const StartOverlay: React.FC<StartOverlayProps> = ({ onStart }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleStart = () => {
    setIsVisible(false);
    setTimeout(() => {
      onStart();
    }, 500); // Wait for fade animation to complete
  };

  if (!isVisible) return null;

  return (
    <div className={styles.overlay} onClick={handleStart}>
      <div className={styles.content}>
        <h1 className={styles.title}>Start Building Your PC</h1>
        <p className={styles.subtitle}>Click anywhere to begin</p>
        <p className={styles.disclaimer}>
          * The 3D models and dimensions shown are approximate representations and may not reflect exact product specifications
        </p>
      </div>
    </div>
  );
};

export default StartOverlay;
