import React, { useState, useEffect, useCallback } from 'react';
import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { usePCBuilderState } from '../../hooks/usePCBuilderState';
import PCBuilderControls from '../PCBuilderControls/PCBuilderControls';
import RealisticScene from './RealisticScene';
import LowEndScene from './LowEndScene';
import { RotateCcw, SwitchCamera } from 'lucide-react';
import StartOverlay from './components/StartOverlay';

const PCBuilderScene: React.FC = () => {
  const {
    components, setComponents,
    isLoading, setIsLoading,
    selectedAsset, setSelectedAsset,
    lightingOptions, updateLightingOption,
    caseOptions, updateCaseOption,
    materialType, setMaterialType,
    lightPresets, saveLightPreset, loadLightPreset,
    pc, 
    setPc,
    addPart,
    removePart,
    checkCompatibility,
  } = usePCBuilderState();

  const [currentScene, setCurrentScene] = useState<'realistic' | 'lowEnd'>('lowEnd');
  const [resetCamera, setResetCamera] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    const checkIfPhone = () => {
      setIsPhone(window.innerWidth <= 768);
    };

    checkIfPhone();
    window.addEventListener('resize', checkIfPhone);

    return () => window.removeEventListener('resize', checkIfPhone);
  }, []);

  const handleSelectComponent = (category: string, modelName: string) => {
    setSelectedAsset(`${category}/${modelName}`);
  };

  const handleDeleteComponent = (id: string, partId: string) => {
    setComponents(prevComponents => prevComponents.filter(c => c.id !== partId));
    removePart(id)
  };

  const handleUpdateComponent = (id: string, newPosition: THREE.Vector3, newRotation: THREE.Euler) => {
    setComponents(prevComponents => 
      prevComponents.map(c => 
        c.id === id ? { ...c, position: newPosition, rotation: newRotation } : c
      )
    );
  };

  const toggleScene = () => {
    setCurrentScene(prev => prev === 'realistic' ? 'lowEnd' : 'realistic');
    setResetCamera(true);
  };

  const handleResetCamera = () => {
    setResetCamera(true);
  };

  return (
    <div className="flex flex-col md:flex-row w-full h-[calc(100vh-64px)] overflow-hidden">
      <div className="relative order-2 md:order-1 flex-shrink-0 h-[calc(55vh-32px)] md:h-[calc(100vh-64px)] w-full md:w-[300px] lg:w-auto lg:min-w-[400px] lg:max-w-[800px] border-t md:border-t-0 md:border-r border-gray-200 overflow-y-auto">
        <PCBuilderControls
          lightingOptions={lightingOptions}
          updateLightingOption={updateLightingOption}
          materialType={materialType}
          setMaterialType={setMaterialType}
          saveLightPreset={saveLightPreset}
          loadLightPreset={loadLightPreset}
          lightPresets={lightPresets}
          pc={pc}
          setPc={setPc}
          components={components}
          setComponents={setComponents}
          addPart={addPart}
          removePart={handleDeleteComponent}
          checkCompatibility={checkCompatibility}
          onClose={() => {}}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setSelectedAsset={setSelectedAsset}
        />
      </div>
      <div className="relative order-1 md:order-2 flex-1 overflow-hidden" style={{ isolation: 'isolate' }}>
        {showOverlay && <StartOverlay onStart={() => setShowOverlay(false)} />}
        <div className="absolute top-2.5 left-4 flex gap-2.5 text-white bg-black/50 p-2 rounded-lg z-10">
        <button onClick={toggleScene}>
          <SwitchCamera size={24} />
        </button>
        <button onClick={handleResetCamera}>
          <RotateCcw size={24} />
        </button>
        </div>
      <Canvas 
        className="relative z-0" 
        style={{ 
          width: '100%', 
          height: isPhone ? '45vh' : '100%',
          minHeight: isPhone ? '300px' : '100%',
          maxHeight: isPhone ? '45vh' : '100%'
        }}>
            {currentScene === 'realistic' ? (
              <RealisticScene 
                components={components}
                onUpdateComponent={handleUpdateComponent}
                lightingOptions={lightingOptions}
                materialType={materialType}
                caseOptions={caseOptions}
                pc={pc}
                setPc={setPc}
                highResEnvPath="/textures/sunset_jhbcentral_8k_cropped.png"
                lowResEnvPath="/textures/meadow_2k.hdr"
                rotation={[0, 0, 0]}
                resetCamera={resetCamera}
                onResetComplete={() => setResetCamera(false)}
              />
            ) : (
              <LowEndScene 
                components={components}
                onUpdateComponent={handleUpdateComponent}
                materialType={materialType}
                lightingOptions={lightingOptions}
                pc={pc}
                setPc={setPc}
                resetCamera={resetCamera}
                onResetComplete={() => setResetCamera(false)}
              />
            )}
      </Canvas>
        </div>
        </div>
  );
};

export default PCBuilderScene;
